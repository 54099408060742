import { faHourglassHalf } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseComponent from "../BaseComponent";
import ExperienceIcon from "../ExperienceIcon";

function ExperienceToEdit({ experience }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex items-center w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="w-5 h-5 text-red-500 md:w-6 md:h-6 min-w-5"
        />
        <div className="text-sm font-bold md:text-base">
          Attenzione l'esperienza richiede delle modifiche
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row md:items-center">
        <div className="flex items-center w-full gap-x-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-full min-w-6">
            <ExperienceIcon kind={experience.category} color="black" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <div>{experience.name}</div>
            </div>
            <div className="w-full text-sm text-gray-400">
              {experience.edit_requested}
            </div>
          </div>
        </div>
        <Link
          to={`/propose/${experience._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Modifica esperienza
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
}

function ExperienceToApprove({ experience }) {
  return (
    <div className="border rounded-md shadow-md">
      <div className="flex w-full p-4 border-b gap-x-4">
        <FontAwesomeIcon
          icon={faHourglassHalf}
          className="w-5 h-5 md:w-6 md:h-6 text-primary"
        />
        <div className="text-sm font-bold md:text-base">
          Richiesta di approvazione inviata
        </div>
      </div>
      <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
        <div className="flex items-center w-full gap-x-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-full ">
            <ExperienceIcon kind={experience.category} color="black" />
          </div>
          <div className="flex flex-col">
            <div className="flex items-center gap-x-2">
              <div>{experience.name}</div>
            </div>
            <div className="text-xs text-gray-600">
              {experience.user_id.name} {experience.user_id.surname}
            </div>
          </div>
        </div>
        <Link
          to={`/experience/preview/${experience._id}`}
          className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
        >
          Vedi anteprima
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    </div>
  );
}

function ExperiencesToComplete({ experience }) {
  return (
    experience &&
    experience.name && (
      <div className="border rounded-md shadow-md">
        <div className="flex w-full p-4 border-b gap-x-4">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="w-5 h-5 text-yellow-500 md:w-6 md:h-6"
          />
          <div className="text-sm font-bold md:text-base">
            Completa la tua esperienza
          </div>
        </div>
        <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
          <div className="flex items-center w-full gap-x-4">
            {experience && experience.name ? (
              <>
                <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                  <ExperienceIcon kind={experience.category} color="black" />
                </div>
                <div className="flex flex-col">
                  <div className="flex items-center gap-x-2">
                    <div>{experience?.name}</div>
                  </div>
                  <div className="text-xs text-gray-600">
                    {experience.user_id.name} {experience.user_id.surname}
                  </div>
                </div>
              </>
            ) : (
              <p className="w-full text-sm text-gray-400 md:w-2/3">
                Attenzione, la tua esperienza risulta incompleta, inserisci i
                dati mancanti per poterla pubblicare
              </p>
            )}
          </div>
          <Link
            to={`/propose/${experience._id}`}
            className="flex items-center justify-between w-64 px-4 py-1 text-white rounded-md md:h-8 bg-primary"
          >
            Completa caricamento
          </Link>
        </div>
      </div>
    )
  );
}

export function ModalStripe({ entity, openModal, setOpenModal, user }) {
  const navigate = useNavigate();
  return (
    <ModalTransition>
      {openModal && (
        <div
          className="fixed inset-0 z-50 bg-black bg-opacity-50"
          key={Date.now()}
        >
          <Modal
            onClose={() => setOpenModal(false)}
            className="mt-0 md:mt-24"
            width="large"
            shouldCloseOnOverlayClick={false}
            onStackChange={(e) => {
              if (e === 0) {
                setOpenModal(false);
              }
            }}
            stackIndex={1}
          >
            <ModalHeader className="border-b">
              <div className="flex items-center w-full text-sm gap-x-4 md:text-base">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="w-5 h-5 text-red-500 md:w-6 md:h-6"
                />
                <div className="font-bold ">
                  Collega stripe al tuo account per ricevere pagamenti
                </div>
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenModal(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </ModalHeader>
            <ModalBody>
              <div className="flex flex-col items-end justify-between w-full h-full gap-4 py-4">
                <div className="flex items-center w-full gap-x-4">
                  <p className="w-full text-sm text-gray-400">
                    {`Attenzione, manca ancora un passaggio per mettere online la
                    tua ${
                      entity === "experience" ? "esperienza" : "struttura"
                    }, vai alla sezione contabilità per completare
                    il setup`}
                  </p>
                </div>
                <button
                  onClick={() => {
                    setOpenModal(false);
                    navigate(
                      entity === "experience"
                        ? `/creatorZone/${user._id}/accounting`
                        : `/partner/${user._id}/accounting`
                    );
                  }}
                  className="flex items-center justify-between w-full h-10 px-4 text-white rounded-md md:w-64 bg-primary"
                >
                  Vai a contabilità
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      )}
    </ModalTransition>
  );
}

export default function Home({ experiences, user }) {
  const experiencesToApprove = experiences.filter(
    (experience) =>
      experience.request_of_approval_made_at &&
      !experience.approved_at &&
      !experience.edit_requested
  );

  const experiencesToEdit = experiences.filter(
    (experience) => experience.edit_requested && !experience.approved_at
  );

  const experiencesApproved = experiences.filter(
    (experience) => experience.approved_at
  );

  const experiencesToComplete = experiences.filter(
    (experience) => !experience.request_of_approval_made_at
  );

  const isStripeConnected = user.stripe_status === "active";
  const [openModal, setOpenModal] = useState();
  const [checkModal, setCheckModal] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckModal(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!isStripeConnected && experiencesApproved.length > 0 && checkModal) {
      setOpenModal(true);
    }
  }, [checkModal]);

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Gli ultimi aggiornamenti
      </h1>
      {openModal && (
        <ModalStripe
          entity="experience"
          openModal={openModal}
          setOpenModal={setOpenModal}
          user={user}
        />
      )}
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        {!isStripeConnected && experiencesApproved.length > 0 && (
          <div className="border rounded-md shadow-md gap-y-6">
            <div className="flex w-full p-4 border-b gap-x-4">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
              <div className="text-sm font-bold md:text-base">
                Collega stripe al tuo account per ricevere pagamenti
              </div>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="w-5 h-5 text-red-500 md:w-6 md:h-6"
              />
            </div>
            <div className="flex flex-col justify-between w-full gap-4 p-4 md:flex-row">
              <div className="flex items-center w-full gap-x-4">
                <p className="w-full text-sm text-gray-400 md:w-2/3">
                  Attenzione, manca ancora un passaggio per mettere online la
                  tua esperienza, vai alla sezione contabilità per completare il
                  setup
                </p>
              </div>
              <Link
                to={`/creatorZone/${user._id}/accounting`}
                className="flex items-center justify-between w-64 h-8 px-4 text-white rounded-md bg-primary"
              >
                Vai a contabilità
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            </div>
          </div>
        )}
        {experiencesToEdit.length > 0 && (
          <>
            {experiencesToEdit.map((experience, index) => (
              <ExperienceToEdit experience={experience} />
            ))}
          </>
        )}
        {experiencesToApprove.length > 0 && (
          <>
            {experiencesToApprove.map((experience, index) => (
              <ExperienceToApprove experience={experience} />
            ))}
          </>
        )}
        {experiencesToComplete.length > 0 && (
          <>
            {experiencesToComplete.map((experience, index) => (
              <ExperiencesToComplete experience={experience} />
            ))}
          </>
        )}
      </div>
    </BaseComponent>
  );
}
