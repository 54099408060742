import { faker } from "@faker-js/faker";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { FeeContext } from "../App";
import RoomModal from "./RoomModal";
import StructureIcon from "./StructureIcon";
import { useGeneralServices } from "./structureComponents/StructureServices";

export default function RoomCard({ room, kind, structure, value }) {
  const { generalServices } = useGeneralServices();
  const [openModal, setOpenModal] = useState(false);
  const intl = useIntl();
  const detailImages = [
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "toilet",
      width: 600,
      height: 400,
    }),
    faker.image.urlLoremFlickr({
      category: "room",
      width: 600,
      height: 400,
    }),
  ];

  const { fees } = useContext(FeeContext);

  const structureFee =
    structure.fee_id === "associations"
      ? fees.associations.fee
      : structure.fee_id
      ? fees.custom.find((f) => f._id === structure.fee_id).fee
      : fees.structures.fee;

  const currentPrice =
    room.prices && room.prices.length > 0
      ? room.prices.find(
          (p) =>
            new Date(p.from_date) < Date.now() &&
            new Date(p.to_date) > Date.now()
        )?.price || room.price
      : room.price;

  const currentPriceWithValue =
    room.prices && room.prices.length > 0 && value && value.length > 1
      ? (
          room.prices.find(
            (p) =>
              new Date(p.from_date) < new Date(value[0]) &&
              new Date(p.to_date) > new Date(value[1])
          ) || {}
        ).price || room.price
      : currentPrice;

  // const minPrice =
  //   room.prices.length > 0
  //     ? Math.min(...room.prices.map((p) => p.price))
  //     : room.price;
  // const maxPrice =
  //   room.prices.length > 0
  //     ? Math.max(...room.prices.map((p) => p.price))
  //     : room.price;

  return (
    <>
      <div className="relative flex flex-col w-full border rounded-lg shadow-lg cursor-pointer md:flex-row md:h-72">
        <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 md:h-72">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
            loop={true}
          >
            {room.images.length > 0
              ? room.images.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt="room"
                      className="object-cover w-full rounded-lg h-72"
                    />
                  </SwiperSlide>
                ))
              : detailImages.map((image) => (
                  <SwiperSlide key={image}>
                    <img
                      src={image}
                      alt="room"
                      className="object-cover w-full rounded-lg h-72"
                    />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
        <div
          className="flex flex-col justify-between w-full md:w-7/12 gap-y-4"
          onClick={() => setOpenModal(true)}
        >
          <div className="flex flex-col justify-between w-full px-4 pt-4 md:ml-4 gap-y-4">
            <div className="flex items-center w-full gap-x-4">
              <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                <StructureIcon kind={kind} color={"black"} />
              </div>
              <div className="font-bold">{room.name}</div>
            </div>
            <div className="grid grid-cols-2 gap-x-4">
              <div className="flex text-sm gap-x-2">
                <div className="font-semibold">
                  {intl.formatMessage({
                    defaultMessage: "Letti:",
                    id: "r2OGhC",
                  })}
                </div>
                <div className="flex flex-col text-gray-400">
                  {room.double_bed > 0 && (
                    <div>
                      {`${room.double_bed} `}{" "}
                      {intl.formatMessage({
                        defaultMessage: "matrimoniali",
                        id: "c7YzvK",
                      })}
                    </div>
                  )}
                  {room.single_bed > 0 && (
                    <div>
                      {`${room.single_bed} `}{" "}
                      {intl.formatMessage({
                        defaultMessage: "singoli",
                        id: "/nLt3I",
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex text-sm gap-x-2">
                <div className="font-semibold">
                  {intl.formatMessage({
                    defaultMessage: "Superficie:",
                    id: "RolgT3",
                  })}
                </div>
                <div className="text-gray-400">
                  {room.size} m<sup>2</sup>
                </div>
              </div>
            </div>
            <div className="flex flex-col text-sm gap-y-2">
              <div className="font-semibold">
                {intl.formatMessage({
                  defaultMessage: "Servizi:",
                  id: "ULAuaJ",
                })}
              </div>
              <div className="flex flex-wrap gap-2">
                {room.services.slice(0, 4).map((service) => (
                  <div
                    key={service}
                    className="flex items-center justify-center px-2 border rounded-md shadow-md text-primary border-primary"
                  >
                    {!isNaN(service)
                      ? generalServices.find((s) => s.id === service)?.name
                      : service}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center w-3/4 text-sm gap-x-2">
              <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
              <div className="text-gray-400">
                {intl.formatMessage({
                  defaultMessage: "Cancellazione",
                  id: "6Mrr/R",
                })}{" "}
                {structure.cancellation_policy === "flessibile"
                  ? intl.formatMessage({
                      defaultMessage: "gratuita fino a 1 settimana prima",
                      id: "JJSwPI",
                    })
                  : structure.cancellation_policy === "moderata"
                  ? intl.formatMessage({
                      defaultMessage: "gratuita fino a 15 giorni prima",
                      id: "Ypc1P9",
                    })
                  : intl.formatMessage({
                      defaultMessage: "gratuita fino a 1 mese prima",
                      id: "rUDGoN",
                    })}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center px-4 py-2 bg-gray-100 md:pt-0 md:pb-4 md:ml-4 md:bg-transparent">
            <div className="flex items-center justify-between w-full gap-x-4">
              {(!value || (value && value.length > 1)) && (
                <div className="font-semibold">
                  {intl.formatMessage({
                    defaultMessage: "Prezzo a notte:",
                    id: "HKjb2E",
                  })}
                </div>
              )}
              <div>
                {value && value.length !== 2 ? (
                  <div className="text-sm font-normal text-gray-500">
                    <FontAwesomeIcon icon={faInfoCircle} />{" "}
                    {intl.formatMessage({
                      defaultMessage:
                        "Seleziona un range di date per vedere il prezzo",
                      id: "iZ0mlU",
                    })}
                  </div>
                ) : (
                  <>
                    {value && value.length > 1 ? (
                      <div className="text-xl font-bold">
                        {Math.ceil(
                          currentPriceWithValue / 100 +
                            (structureFee * currentPriceWithValue) / 10000
                        ).toFixed(0)}{" "}
                        €
                      </div>
                    ) : (
                      <div className="text-xl font-bold">
                        {Math.ceil(
                          currentPrice / 100 +
                            (structureFee * currentPrice) / 10000
                        ).toFixed(0)}{" "}
                        €
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <RoomModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          structure={structure}
          room={room}
          value={value}
        />
      )}
    </>
  );
}
