import React, { useEffect, useState } from "react";
import SearchBar from "./SearchBar";
import SearchBarCommunity from "./SearchBarCommunity";
import SearchBarMobile from "./SearchBarMobile";
import SearchBarStructure from "./SearchBarStructure";
import SearchBarStructureMobile from "./SearchBarStructureMobile";

export default function PageBody({
  children,
  withSearchBar = true,
  setSelectedTab,
  selectedTab,
  isStructure = false,
  selectedLocation,
  setSelectedLocation = null,
  isHomePage = false,
  isCommunity = false,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function per rimuovere l'event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="relative w-full -mt-60 md:-md-52 bg-white rounded-[60px] md:rounded-[210px] 2xl:-mt-60 lg:px-10">
        {withSearchBar && !isStructure && isHomePage && (
          <>
            {windowWidth >= 768 ? (
              <SearchBar
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                isHomePage={isHomePage}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            ) : (
              <SearchBarMobile
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            )}
          </>
        )}
        {withSearchBar &&
          !isStructure &&
          setSelectedLocation &&
          !isCommunity &&
          !isHomePage && (
            <>
              {windowWidth >= 768 ? (
                <SearchBar
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                />
              ) : (
                <SearchBarMobile
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                />
              )}
            </>
          )}
        {withSearchBar && isStructure && (
          <>
            {windowWidth >= 768 ? (
              <SearchBarStructure
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            ) : (
              <SearchBarStructureMobile
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            )}
          </>
        )}
        {isCommunity && (
          <SearchBarCommunity
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          />
        )}
        <div className="mx-2 -mb-6 md:mx-8 max-w-screen-2xl 2xl:mx-auto">
          <div className="pt-0.5 md:p-8 min-h-[70vh]">{children}</div>
        </div>
      </div>
    </>
  );
}
