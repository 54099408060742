import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Bounce, ToastContainer } from "react-toastify";
import { SwiperSlide } from "swiper/react";
import Carousel from "../components/Carousel";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import SingleBox from "../components/SingleBox";
import SingleExperience from "../components/SingleExperience";
import SingleLoad from "../components/SingleLoad";
import SingleStructure from "../components/SingleStructure";
import { LocationValleys } from "../components/utils/LocationValleys";

export default function DiscoverLocation() {
  const [experiences, setExperiences] = useState([]);
  const [structures, setStructures] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem("selectedExperienceTab") || "wine"
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [startFiltering, setStartFiltering] = useState(false);
  const [initialDataExperiences, setInitialDataExperiences] = useState([]);
  const [initialDataStructures, setInitialDataStructures] = useState([]);
  const [initialDataBoxes, setInitialDataBoxes] = useState([]);
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [selectedLocation, setSelectedLocation] = useState(
    LocationValleys[id - 1].name
  );
  document.title = `Esco | ${selectedLocation}`;

  const experienceOfCategory = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/experiencesApprovedOfCategoryLimited`,
        {
          category: selectedTab,
          limit: 50,
        }
      )
      .then(async (res) => {
        setExperiences(res.data);
        setInitialDataExperiences(res.data);
        await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/api/structuresApprovedPublic`
          )
          .then(async (res) => {
            setStructures(res.data);
            setInitialDataStructures(res.data);
            await axios
              .post(
                `${process.env.REACT_APP_SERVER_URL}/api/escoboxesOfCategoryLimited`,
                {
                  category: selectedTab,
                  limit: 50,
                }
              )
              .then((res) => {
                if (res.data.msg === "No escoBoxes") {
                  setBoxes([]);
                  setInitialDataBoxes([]);
                } else {
                  setBoxes(res.data);
                  setInitialDataBoxes(res.data);
                }
                setStartFiltering(true);
              })
              .catch((err) => {
                console.log(err, "err");
              });
          })
          .catch((err) => {
            console.log(err, "err");
          });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    setLoading(true);
    experienceOfCategory();
  }, [selectedTab]);

  useEffect(() => {
    if (startFiltering) {
      let dataExperiences = initialDataExperiences;
      let dataStructures = initialDataStructures;
      let dataBoxes = initialDataBoxes;

      if (selectedLocation) {
        const villages = LocationValleys.filter(
          (valley) => valley.name === selectedLocation
        )[0].villages;
        dataExperiences = dataExperiences.filter((item) => {
          return villages.some(
            (village) =>
              village.toLowerCase() ===
                (item.address.municipality || "").toLowerCase() ||
              village.toLowerCase() ===
                (item.address.locality || "").toLowerCase()
          );
        });
        dataStructures = dataStructures.filter((item) => {
          return villages.some(
            (village) =>
              village.toLowerCase() ===
                (item.address.municipality || "").toLowerCase() ||
              village.toLowerCase() ===
                (item.address.locality || "").toLowerCase()
          );
        });
        dataBoxes = dataBoxes.filter(
          (item) => item.city.toLowerCase() === selectedLocation.toLowerCase()
        );
      }

      setExperiences(dataExperiences);
      setStructures(dataStructures);
      setBoxes(dataBoxes);

      setLoading(false);
    }
  }, [
    initialDataExperiences,
    selectedLocation,
    initialDataStructures,
    startFiltering,
    initialDataBoxes,
  ]);

  useEffect(() => {
    setSelectedLocation(LocationValleys[id - 1].name);
  }, [id]);

  useEffect(() => {
    if (
      localStorage.getItem("selectedExperienceTab") === null ||
      localStorage.getItem("selectedExperienceTab") !== selectedTab
    ) {
      localStorage.setItem("selectedExperienceTab", selectedTab);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!selectedLocation) {
      navigate("/");
    }
  }, [selectedLocation]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(4); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            key={selectedTab}
            url={
              selectedTab === "wine"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_senior-couple-at-wine-tasting-2022-08-04-17-49-16-utc.mp4"
                : selectedTab === "art"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/2_historical-columns-2022-01-18-23-34-52-utc.mp4"
                : selectedTab === "outdoor"
                ? "https://escopublic.s3.eu-north-1.amazonaws.com/GX014697.mp4"
                : "https://escopublic.s3.eu-north-1.amazonaws.com/2_chef-and-students-smashing-garlic-at-cooking-maste-2022-08-05-01-52-56-utc.mov"
            }
            description={selectedLocation}
            isVideo={true}
          />
        </div>
        <div className="relative">
          <PageBody
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            isHomePage={true}
          >
            {/* <div className="grid grid-cols-1 gap-8 mt-32 mb-16 md:grid-cols-2 lg:grid-cols-3">
              <div></div>
              <div className="lg:col-span-2">
                <h1 className="text-3xl md:text-4xl text-secondary">
                  Le nostre esperienze
                </h1>
              </div>
            </div> */}
            <div className="flex flex-col gap-6 mt-8 md:mt-10">
              <div className="sticky z-10 flex flex-col w-full px-1 pt-8 pb-4 bg-white top-5 md:top-0 md:py-8 gap-y-4 box-shadow-md">
                <div className="flex flex-col items-center justify-between w-full gap-4 md:flex-row ">
                  <h1 className="text-xl font-bold text-start md:text-center md:text-4xl text-secondary">
                    {selectedLocation}
                  </h1>
                  <div className="flex items-center gap-4">
                    <div className="md:w-56">
                      <Select
                        isSearchable
                        isMulti={false}
                        closeMenuOnSelect={true}
                        menuPortalTarget={document.body}
                        menuPosition="absolute"
                        id="city"
                        options={LocationValleys.map((valley) => {
                          return {
                            value: valley.id,
                            label: valley.name,
                          };
                        })}
                        defaultValue={{
                          value: LocationValleys.filter(
                            (valley) => valley.name === selectedLocation
                          )[0].id,
                          label: selectedLocation,
                        }}
                        onChange={(value) => {
                          console.log(value.value);
                          navigate(`/discover/${value.value}`);
                        }}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        navigate("/");
                      }}
                      className="px-4 py-2 text-white rounded-md bg-secondary"
                    >
                      <FontAwesomeIcon icon={faHome} />
                      {/* Torna alla home */}
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex items-center w-full px-4 mb-4 md:px-2 gap-x-4">
                {/* <div className="w-1/2 border-b border-secondary" /> */}
                <div className="text-xl font-bold text-start md:text-center md:text-4xl text-escoBlack">
                  EscoBox
                </div>
                <div className="w-full border-b border-secondary" />
              </div>
              {boxes.length === 0 && !loading ? (
                <div className="w-full col-span-3 text-2xl text-gray-500 h-96">
                  Nessun EscoBox disponibile al momento
                </div>
              ) : (
                <Carousel slidesPerView={slidesPerView}>
                  {!loading ? (
                    boxes
                      .reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / slidesPerView);

                        if (!resultArray[chunkIndex]) {
                          resultArray[chunkIndex] = []; // start a new chunk
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                      }, [])
                      .map((group, i) => (
                        <SwiperSlide key={i}>
                          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                            <>
                              {group.map((item) => (
                                <SingleBox item={item} key={item.id} />
                              ))}
                            </>
                          </div>
                        </SwiperSlide>
                      ))
                  ) : (
                    <SwiperSlide>
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                        <SingleLoad length={slidesPerView} />
                      </div>
                    </SwiperSlide>
                  )}
                </Carousel>
              )}
              <div className="flex items-center w-full px-2 mb-4 gap-x-4">
                {/* <div className="w-1/2 border-b border-secondary" /> */}
                <div className="text-xl font-bold text-start md:text-center md:text-4xl text-escoBlack">
                  Esperienze
                </div>
                <div className="w-full border-b border-secondary" />
              </div>
              {experiences.length === 0 && !loading ? (
                <div className="w-full col-span-3 text-2xl text-gray-500 h-96">
                  Nessuna esperienza disponibile al momento
                </div>
              ) : (
                <Carousel slidesPerView={slidesPerView}>
                  {!loading ? (
                    experiences
                      .reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / slidesPerView);

                        if (!resultArray[chunkIndex]) {
                          resultArray[chunkIndex] = []; // start a new chunk
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                      }, [])
                      .map((group, i) => (
                        <SwiperSlide key={i}>
                          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                            <>
                              {group.map((item) => (
                                <SingleExperience item={item} key={item.id} />
                              ))}
                            </>
                          </div>
                        </SwiperSlide>
                      ))
                  ) : (
                    <SwiperSlide>
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                        <SingleLoad length={4} />
                      </div>
                    </SwiperSlide>
                  )}
                </Carousel>
              )}
              <div className="flex items-center w-full px-2 mb-4 gap-x-4">
                {/* <div className="w-1/2 border-b border-secondary" /> */}
                <div className="text-xl font-bold text-start md:text-center md:text-4xl text-escoBlack">
                  Strutture
                </div>
                <div className="w-full border-b border-secondary" />
              </div>
              {structures.length === 0 && !loading ? (
                <div className="w-full col-span-3 text-2xl text-gray-500 h-96">
                  Nessuna struttura disponibile al momento
                </div>
              ) : (
                <Carousel slidesPerView={slidesPerView}>
                  {!loading ? (
                    structures
                      .reduce((resultArray, item, index) => {
                        const chunkIndex = Math.floor(index / slidesPerView);

                        if (!resultArray[chunkIndex]) {
                          resultArray[chunkIndex] = []; // start a new chunk
                        }

                        resultArray[chunkIndex].push(item);

                        return resultArray;
                      }, [])
                      .map((group, i) => (
                        <SwiperSlide key={i}>
                          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                            <>
                              {group.map((item) => (
                                <SingleStructure item={item} key={item.id} />
                              ))}
                            </>
                          </div>
                        </SwiperSlide>
                      ))
                  ) : (
                    <SwiperSlide>
                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4">
                        <SingleLoad length={4} />
                      </div>
                    </SwiperSlide>
                  )}
                </Carousel>
              )}
            </div>
            <div className="fixed z-50">
              <ToastContainer
                containerId={`SingleExperienceContainer`}
                position="bottom-left"
                autoClose={2000}
                hideProgressBar
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="light"
                transition={Bounce}
                stacked
              />
            </div>
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
