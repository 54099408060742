import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import BaseComponent from "../BaseComponent";
import StructureIcon from "../StructureIcon";
import ManageFaq from "./manageSection/ManageFaq";
import ManageSlideshow from "./manageSection/ManageSlideshow";

export default function ManageWebsite() {
  const [sectionOpen, setSectionOpen] = useState(null);
  const sections = [
    {
      id: 1,
      name: "Gestione FAQ",
    },
    {
      id: 2,
      name: "Gestione Video Slideshow",
    },
  ];

  return (
    <BaseComponent
      titleComponent={
        sectionOpen ? (
          <div className="flex items-center text-2xl gap-x-4 text-primary">
            <button
              className="mt-0.5 text-base"
              onClick={() => setSectionOpen(null)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h1>Gestione sito</h1>
          </div>
        ) : (
          <h1 className="text-2xl text-primary">Gestione sito</h1>
        )
      }
    >
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md min-h-[512px] overflow-x-auto overflow-y-auto p-10 my-4">
        {!sectionOpen ? (
          <>
            {sections.map((section, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-white rounded-md"
              >
                <div className="flex items-center gap-x-4">
                  <StructureIcon name={section.name} />
                  <p className="text-lg font-semibold">{section.name}</p>
                </div>
                <div className="flex items-center gap-x-4">
                  <button
                    onClick={() => setSectionOpen(section.id)}
                    className="flex items-center px-4 py-2 text-white rounded-md gap-x-4 bg-primary"
                  >
                    <p>Gestisci</p>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {sectionOpen === 1 && <ManageFaq />}
            {sectionOpen === 2 && <ManageSlideshow />}
          </>
        )}
      </div>
    </BaseComponent>
  );
}
