import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faEllipsisVertical,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import BaseComponent from "../BaseComponent";

export default function Users() {
  const [Users, setUsers] = useState(null);

  const [loading, setLoading] = useState(true);

  const UsersDetail = async () => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("user");
    await axios
      .post(`${process.env.REACT_APP_SERVER_URL}/api/usersList/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    UsersDetail();
  }, []);

  return (
    <BaseComponent title="Utenti">
      <div className="bg-gray-100 flex flex-col gap-y-4 rounded-md h-[512px] overflow-y-auto py-4 px-10 my-4">
        {!loading && Users ? (
          <div className="flow-root mt-8">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                      >
                        Avatar
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Nome
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Ha strutture
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Ha esperienze
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Ha attivato stripe
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                      >
                        Iscrizione
                      </th>
                      <th
                        scope="col"
                        className="relative py-3 pl-3 pr-4 sm:pr-0"
                      >
                        <span className="sr-only">Azioni</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 ">
                    {Users.map((User, index) => (
                      <tr key={User._id}>
                        <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-0">
                          <div className="flex items-center">
                            <div className="flex items-center justify-center w-12 h-8 rounded-full ">
                              <FontAwesomeIcon
                                icon={faUserCircle}
                                className="text-2xl text-primary"
                              />
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {User.email}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {User.name} {User.surname}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="w-full text-sm text-center text-gray-500">
                            {User.has_structures ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-green-500"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="text-red-500"
                              />
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="w-full text-sm text-center text-gray-500">
                            {User.has_experiences ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-green-500"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="text-red-500"
                              />
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="w-full text-sm text-center text-gray-500">
                            {User.stripe_id &&
                            User.stripe_status === "active" ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-green-500"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="text-red-500"
                              />
                            )}
                          </div>
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {dayjs(User.createdAt).format("DD/MM/YYYY")}
                          </div>
                        </td>
                        <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0">
                          <div className="flex items-center justify-end gap-x-6">
                            <button
                              type="button"
                              className="px-2 py-1 text-xs font-medium text-white rounded-md bg-primary"
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className="text-white"
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : loading ? (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-[480px]">
            Nessun utente trovato
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
