import axios from "axios";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { SwiperSlide } from "swiper/react";
import Carousel from "../components/Carousel";
import Cover from "../components/Cover";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import SingleArticle from "../components/SingleArticle";
import SingleLoad from "../components/SingleLoad";

export default function Community() {
  const [articles, setArticles] = useState([]);
  const [shareArticles, setShareArticles] = useState([]);
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [slidesPerView, setSlidesPerView] = useState(3);

  const getArticles = async () => {
    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/articlesLimited`)
      .then((res) => {
        console.log(res, "articles");
        setArticles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    document.title = "Esco | Community";
    setLoading(true);
    setTimeout(() => {
      getArticles();
      // setShareArticles(generateFakeData({ kind: "escoShare", amount: 4 }));
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSlidesPerView(1); // Per dispositivi mobili
      } else if (width >= 768 && width < 1024) {
        setSlidesPerView(2); // Per tablet
      } else {
        setSlidesPerView(3); // Per desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Chiama immediatamente per impostare il valore iniziale

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <header>
        <Header />
      </header>
      <main>
        <div>
          <Cover
            url={"https://escopublic.s3.eu-north-1.amazonaws.com/blog.jpeg"}
            description={intl.formatMessage({
              defaultMessage: "Entra nella nostra community",
              id: "ozS5Qm",
            })}
            isVideo={false}
          />
        </div>
        <div className="relative">
          <PageBody
            isCommunity
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
          >
            <h1 className="mt-16 mb-8 text-xl font-bold text-center md:my-16 md:text-4xl text-secondary">
              {intl.formatMessage({
                defaultMessage: "Lasciati ispirare",
                id: "omNoE9",
              })}
            </h1>
            {loading ? (
              <Carousel slidesPerView={slidesPerView}>
                <SwiperSlide>
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-4">
                    <SingleLoad length={slidesPerView} />
                  </div>
                </SwiperSlide>
              </Carousel>
            ) : (
              <div>
                <div className="grid mx-6 md:mx-0 md:grid-cols-3 gap-x-4">
                  {articles.slice(0, 6).map((item) => (
                    <SingleArticle item={item} key={item.id} />
                  ))}
                </div>
                {/* <QuizCta /> */}
                {/* <h1 className="mb-20 text-3xl text-center md:text-4xl mt-28 text-secondary">
                  Dal nostro blog
                </h1> */}
                <div className="grid mx-4 md:mx-0 md:grid-cols-3 gap-x-4">
                  {articles.slice(6, 9).map((item) => (
                    <SingleArticle item={item} key={item.id} />
                  ))}
                </div>
                {/* <h1 className="mt-16 mb-20 text-3xl text-center md:text-4xl text-secondary">
                  Segui le orme di altri esploratori
                </h1>

                <div className="grid grid-cols-2 gap-x-8">
                  {shareArticles.map((item) => (
                    <SingleShareArticle item={item} key={item.id} />
                  ))}
                </div> */}
              </div>
            )}
          </PageBody>
        </div>
      </main>
      <Footer />
    </div>
  );
}
