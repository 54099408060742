import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import Spinner from "@uidu/spinner";
import axios from "axios";
import React, { useState } from "react";

export default function ModalSuggestEdit({ setOpenModal, kind, entityId }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEditRequest = async () => {
    setLoading(true);
    const suggestion = document.getElementById("suggestion").value;

    const currentError = null;

    if (suggestion === "") {
      currentError = true;
    }

    setError(currentError);

    if (currentError) {
      return;
    }

    const token = localStorage.getItem("token");

    if (!token) {
      return;
    }

    const user = localStorage.getItem("user");
    const data = {
      requested_edit_at: new Date(),
      edit_requested: suggestion,
    };

    if (kind === "structure") {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${entityId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          window.location.href = `/adminArea/${user}`;
        })
        .catch(() => {
          alert("Errore durante la pubblicazione della struttura");
        });
    } else if (kind === "experience") {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${entityId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          window.location.href = `/adminArea/${user}/approveExperiences`;
        });
    }
    setOpenModal(false);
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
          className="mt-0 md:mt-10"
          shouldCloseOnOverlayClick={false}
          width="large"
        >
          <ModalHeader>
            <div className="flex items-center gap-x-4">
              <h3 className="text-2xl">Suggerisci modifiche</h3>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setOpenModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col gap-y-2">
              <textarea
                className="h-full w-full p-6 mb-6 border-2 border-gray-200 resize-none rounded-[20px] min-h-[200px]"
                id="suggestion"
                placeholder="Inserisci le modifiche suggerite"
              ></textarea>
              {error && (
                <p className="-mt-2 text-sm text-red-500">
                  Inserisci le modifiche suggerite
                </p>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
              <button
                className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Annulla
              </button>
              <button
                className="w-full h-10 text-sm text-white rounded-lg bg-primary md:w-52"
                onClick={() => {
                  if (loading) {
                    return;
                  } else {
                    sendEditRequest();
                  }
                }}
              >
                {loading ? (
                  <Spinner size="small" invertColor />
                ) : (
                  "Invia richiesta di modifica"
                )}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
