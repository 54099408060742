import { faker } from "@faker-js/faker";
import {
  faExclamationTriangle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import { motion, useAnimation } from "framer-motion";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ElementsCartContext } from "../App";
import geotag from "../assets/icons/GEOTAG.svg";
import stella from "../assets/icons/STELLA.svg";
import voucher from "../assets/icons/VOUCHER.svg";
import ExperienceIcon from "../components/ExperienceIcon";
import Footer from "../components/Footer";
import Header from "../components/Header";
import StructureIcon from "../components/StructureIcon";

export default function Cart() {
  const [userLogged, setUserLogged] = useState(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [allElementValid, setAllElementValid] = useState(false);
  const [cart, setCart] = useState(null);
  const { elements, setElements } = useContext(ElementsCartContext);
  const ref = useRef(null);
  const controls = useAnimation();
  const notify = () =>
    toast.info("Funzionalità non ancora disponibile, sarà attiva a breve!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

  const notifyInvalid = () =>
    toast.error("Alcuni elementi del carrello non sono più disponibili", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });

  const detailImages = useMemo(() => {
    const images = [
      faker.image.urlLoremFlickr({
        category: "nature",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "montain",
        width: 600,
        height: 400,
      }),
      faker.image.urlLoremFlickr({
        category: "lake",
        width: 600,
        height: 400,
      }),
    ];
    return images;
  }, []);

  const averageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  async function Start() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/logged`)
        .then((res) => {
          setUserLogged(true);
        })
        .catch(() => {
          setUserLogged(false);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
        });
    } else {
      setUserLogged(false);
    }
  }

  async function getCart() {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/api/cart/${localStorage.getItem(
          "user"
        )}`
      )
      .then((res) => {
        if (!res.data.message) {
          setCart(res.data.cart);
          setElements(res.data.elements);
          setAllElementValid(
            res.data.elements.every((element) => element.is_valid)
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const deleteCart = async (id) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/api/cart/delete/${id}`)
      .then((res) => {
        setCart(null);
        setElements([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCart = async (id, data) => {
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    await axios
      .put(`${process.env.REACT_APP_SERVER_URL}/api/cart/edit/${id}`, data)
      .then((res) => {
        console.log(res.data);
        getCart();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = (id) => {
    if (elements.length === 1) {
      deleteCart(cart._id);
    } else {
      const data = {
        elements: cart.elements.filter((element) => element._id !== id),
      };
      updateCart(cart._id, data);
    }
  };

  useEffect(() => {
    document.title = "Esco | Carrello";
    document.getElementById("page-top").scrollIntoView({ behavior: "smooth" });
    Start();
  }, []);

  useEffect(() => {
    if (userLogged !== null) {
      getCart();
    }
  }, [userLogged]);

  const createSession = async () => {
    if (window.location.toString() === "https://www.esco.zone/cart") {
      notify();
    } else {
      const token = localStorage.getItem("token");
      setIsCreatingSession(true);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/orderCreateStripeSession`,
          {
            cart_id: cart._id,
          }
        )
        .then((res) => {
          setIsCreatingSession(false);
          navigate(`/payment/${res.data.client_secret}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const referenceElement = ref.current;
      if (!referenceElement || elements.length <= 1) return;

      const referencePosition = referenceElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (referencePosition <= windowHeight) {
        // Nascondi la sezione fissa quando l'elemento di riferimento è visibile
        controls.start({ y: 100, opacity: 0 });
      } else {
        // Mostra la sezione fissa quando l'elemento di riferimento non è visibile
        controls.start({ y: 0, opacity: 1 });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [controls, ref]);

  const total =
    elements &&
    elements.map((element) => element.total_price).reduce((a, b) => a + b, 0);

  return (
    <div id="page-top">
      <header>
        <div className="relative z-50 md:-mt-16">
          <Header />
        </div>
      </header>
      <main>
        <div className="mx-0 2xl:mx-8">
          <div className="relative mx-4 md:mx-16 max-w-screen-2xl 2xl:mx-auto">
            <div className="flex flex-col w-full bg-[#F8F9F9] -mt-6 md:mt-16 rounded-[60px] p-4 md:p-8 !pt-32 !pb-20 min-h-[calc(100vh-20px)] md:min-h-[500px] ">
              <div className="flex items-center gap-x-4">
                {/* <img src={carrello} alt="carrello" className="w-8 h-8" /> */}
                <h1 className="text-3xl text-primary">
                  {intl.formatMessage({
                    defaultMessage: "Il tuo carrello",
                    id: "PD6WKs",
                  })}
                </h1>
              </div>
              {!loading && cart && elements.length > 0 ? (
                <div className="grid grid-cols-1 gap-20 mt-8 md:grid-cols-6">
                  <div className="flex flex-col md:col-span-4 gap-y-4">
                    {elements.map((element, index) => {
                      if (element.experience) {
                        const experience = element.experience;
                        return (
                          <Link
                            to={`/experiences/${experience._id}`}
                            className="relative flex flex-col w-full bg-white rounded-lg shadow-lg md:flex-row md:h-72"
                          >
                            <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 h-60 md:h-72">
                              <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                                loop={true}
                              >
                                {experience.images.length > 0
                                  ? experience.images.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-60 md:h-72"
                                        />
                                      </SwiperSlide>
                                    ))
                                  : detailImages.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))}
                              </Swiper>
                            </div>
                            <div className="flex flex-col justify-between w-full p-4 text-sm md:ml-4 md:w-7/12 gap-y-2">
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center w-full gap-x-4">
                                      <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                                        {element.start_time ? (
                                          <ExperienceIcon
                                            kind={experience.category}
                                            color="black"
                                          />
                                        ) : (
                                          <img
                                            src={voucher}
                                            alt="is a present"
                                            className="w-6 h-6"
                                          />
                                        )}
                                      </div>
                                      <div className="font-bold">
                                        {experience.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full mt-1 gap-x-4">
                                    <div className="flex items-center mr-12 gap-x-2">
                                      <img
                                        src={geotag}
                                        alt="geotag"
                                        className="w-4"
                                      />
                                      <div className="text-center">
                                        {experience.address
                                          ? experience.address.municipality
                                            ? experience.address.municipality
                                            : experience.address.village
                                          : "Pertica Bassa"}
                                      </div>
                                    </div>
                                    {experience.approved_at && (
                                      <div className="flex items-center gap-x-1.5">
                                        <img
                                          src={stella}
                                          alt="stella"
                                          className="w-4"
                                        />
                                        <div>
                                          {experience.reviews
                                            ? averageReviews(experience.reviews)
                                            : "0"}
                                          /5
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleDelete(element._id);
                                  }}
                                  className="absolute z-10 flex items-center justify-center w-8 h-8 text-red-500 bg-white border-2 border-gray-100 rounded-full shadow-lg -right-4 -top-4"
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </button>
                              </div>
                              {element.start_time ? (
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="flex items-center w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Data:",
                                        id: "m19lxP",
                                      })}
                                    </div>
                                    {element.experience.is_recurrent ? (
                                      <div className="ml-4 text-primary">
                                        {dayjs(element.start_date).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </div>
                                    ) : (
                                      <div className="ml-4 text-primary">
                                        {dayjs(
                                          element.experience.data_info
                                            .start_date
                                        ).format("DD/MM/YYYY")}
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex items-center w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Ora:",
                                        id: "JOd7sM",
                                      })}
                                    </div>
                                    <div className="ml-4 text-primary">
                                      {element.start_time}
                                    </div>
                                  </div>
                                  {element.guests_number.is_group ? (
                                    <div className="flex items-center w-full col-span-2 md:h-8">
                                      <div className="font-semibold">
                                        {intl.formatMessage({
                                          defaultMessage:
                                            "Prenotato per un gruppo:",
                                          id: "bM0wJ6",
                                        })}
                                      </div>
                                      <div className="ml-4 text-primary">
                                        {intl.formatMessage(
                                          {
                                            defaultMessage: "max {max} persone",
                                            id: "NJTei8",
                                          },
                                          {
                                            max: experience.max_private_group_size,
                                          }
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex items-center w-full col-span-2 md:h-8">
                                      <div className="font-semibold">
                                        {intl.formatMessage({
                                          defaultMessage: "N° di persone:",
                                          id: "iR/Jy5",
                                        })}
                                      </div>
                                      {!element.total_price ? (
                                        <div className="flex flex-col ml-4 text-primary">
                                          {element.guests_number.adults ? (
                                            <div>
                                              {element.guests_number.adults}{" "}
                                              {intl.formatMessage({
                                                defaultMessage: "partecipanti",
                                                id: "iUU6TV",
                                              })}
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : (
                                        <div className="flex flex-col ml-4 text-primary">
                                          {element.guests_number.adults ? (
                                            <div>
                                              {element.guests_number.adults}{" "}
                                              {intl.formatMessage({
                                                defaultMessage: "adulti",
                                                id: "HR89WG",
                                              })}
                                            </div>
                                          ) : null}
                                          {element.guests_number.children ? (
                                            <div>
                                              {element.guests_number.children}{" "}
                                              {intl.formatMessage({
                                                defaultMessage: "bambini",
                                                id: "ImjG1h",
                                              })}
                                            </div>
                                          ) : null}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="flex items-start w-full col-span-2 md:h-8 gap-x-6 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Il tuo messaggio:",
                                        id: "tc5D15",
                                      })}
                                    </div>
                                    <div className="ml-4 line-clamp-2 text-primary">
                                      {element.message}
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "N° di persone:",
                                        id: "iR/Jy5",
                                      })}
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults}{" "}
                                          {intl.formatMessage({
                                            defaultMessage: "adulti",
                                            id: "HR89WG",
                                          })}
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <div>
                                          {element.guests_number.children}{" "}
                                          {intl.formatMessage({
                                            defaultMessage: "bambini",
                                            id: "ImjG1h",
                                          })}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!element.is_valid ? (
                                <div className="flex items-center font-bold text-yellow-500 gap-x-2">
                                  <FontAwesomeIcon
                                    className="text-xl"
                                    icon={faExclamationTriangle}
                                  />
                                  <div>
                                    {intl.formatMessage({
                                      defaultMessage:
                                        "Esperienza non più disponibile",
                                      id: "gENB4U",
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {element.total_price ? (
                                    <div className="flex items-center justify-between w-full">
                                      <div className="text-lg font-bold">
                                        {intl.formatMessage({
                                          defaultMessage: "Prezzo:",
                                          id: "EGV7fv",
                                        })}
                                      </div>
                                      <div className="text-xl font-bold md:text-2xl">
                                        {Math.ceil(element.total_price).toFixed(
                                          0
                                        ) + ",00"}{" "}
                                        €
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex items-center justify-between w-full">
                                      <div className="text-lg font-bold"></div>
                                      <div className="text-xl font-bold md:text-2xl">
                                        {intl.formatMessage({
                                          defaultMessage: "Gratuito",
                                          id: "AMJRek",
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </Link>
                        );
                      }
                      if (element.structure) {
                        const structure = element.structure;
                        return (
                          <Link
                            to={`/structures/${structure._id}`}
                            className="relative flex flex-col w-full bg-white rounded-lg shadow-lg md:flex-row md:h-72"
                          >
                            <div className="relative w-full -mt-px overflow-hidden rounded-lg md:w-5/12 h-60 md:h-72">
                              <Swiper
                                navigation={true}
                                modules={[Navigation]}
                                className="mySwiper"
                                loop={true}
                              >
                                {structure.images.length > 0
                                  ? structure.images.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-60 md:h-72"
                                        />
                                      </SwiperSlide>
                                    ))
                                  : detailImages.map((image) => (
                                      <SwiperSlide key={image}>
                                        <img
                                          src={image}
                                          alt="room"
                                          className="object-cover w-full rounded-lg h-72"
                                        />
                                      </SwiperSlide>
                                    ))}
                              </Swiper>
                            </div>
                            <div className="flex flex-col justify-between w-full p-4 text-sm md:ml-4 md:w-7/12 gap-y-2">
                              <div className="flex items-center justify-between w-full">
                                <div className="flex flex-col gap-y-2">
                                  <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center w-full gap-x-4">
                                      <div className="flex items-center justify-center w-6 h-6 rounded-full ">
                                        {element.start_date ? (
                                          <StructureIcon
                                            kind={structure.kind}
                                            color="black"
                                          />
                                        ) : (
                                          <img
                                            src={voucher}
                                            alt="is a present"
                                            className="w-6 h-6"
                                          />
                                        )}
                                      </div>
                                      <div className="font-bold">
                                        {structure.name}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center w-full mt-1 gap-x-4">
                                    <div className="flex items-center mr-12 gap-x-2">
                                      <img
                                        src={geotag}
                                        alt="geotag"
                                        className="w-4"
                                      />
                                      <div className="text-center">
                                        {structure.address
                                          ? structure.address.municipality
                                            ? structure.address.municipality
                                            : structure.address.village
                                          : "Pertica Bassa"}
                                      </div>
                                    </div>
                                    {structure.approved_at && (
                                      <div className="flex items-center gap-x-1.5">
                                        <img
                                          src={stella}
                                          alt="stella"
                                          className="w-4"
                                        />
                                        <div>
                                          {structure.reviews
                                            ? averageReviews(structure.reviews)
                                            : "0"}
                                          /5
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleDelete(element._id);
                                  }}
                                  className="absolute z-10 flex items-center justify-center w-8 h-8 text-red-500 bg-white border-2 border-gray-100 rounded-full shadow-lg -right-4 -top-4"
                                >
                                  <FontAwesomeIcon icon={faXmark} />
                                </button>
                              </div>
                              <div className="grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-2">
                                {element.start_date ? (
                                  <div className="flex w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Periodo:",
                                        id: "/ZeWkR",
                                      })}
                                    </div>
                                    <div className="flex ml-4 md:flex-col text-primary">
                                      <div>
                                        {intl.formatMessage({
                                          defaultMessage: "Dal",
                                          id: "1dw/jT",
                                        })}{" "}
                                        &nbsp;
                                        {dayjs(element.start_date).format(
                                          "DD/MM/YY"
                                        )}
                                        {"  "} &nbsp;
                                      </div>
                                      <div>
                                        {intl.formatMessage({
                                          defaultMessage: "al",
                                          id: "sQ/NTw",
                                        })}
                                        {"  "}&nbsp;
                                        {dayjs(element.end_date).format(
                                          "DD/MM/YY"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-start w-full col-span-2 md:h-8 gap-x-6 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Il tuo messaggio:",
                                        id: "tc5D15",
                                      })}
                                    </div>
                                    <div className="ml-4 line-clamp-2 text-primary">
                                      {element.message}
                                    </div>
                                  </div>
                                )}
                                {element.start_date && (
                                  <div className="flex w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "N° di persone:",
                                        id: "iR/Jy5",
                                      })}
                                    </div>
                                    <div className="flex ml-4 md:flex-col text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults}{" "}
                                          {intl.formatMessage({
                                            defaultMessage: "adulti",
                                            id: "HR89WG",
                                          })}
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <>
                                          <span className="md:hidden">
                                            &nbsp; -{" "}
                                          </span>
                                          <div>
                                            {element.guests_number.children}{" "}
                                            {intl.formatMessage({
                                              defaultMessage: "bambini",
                                              id: "ImjG1h",
                                            })}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="grid grid-cols-1 gap-2 md:gap-4 md:grid-cols-2">
                                <div className="flex w-full md:h-8 ">
                                  <div className="font-semibold">
                                    {element.booked_rooms.length > 1
                                      ? intl.formatMessage({
                                          defaultMessage: "Camere",
                                          id: "2Kh9YR",
                                        })
                                      : intl.formatMessage({
                                          defaultMessage: "Camera",
                                          id: "J6ywQ3",
                                        })}
                                    :
                                  </div>
                                  <div className="flex flex-col ml-4 text-primary">
                                    {element.booked_rooms.map((room) => (
                                      <div>
                                        1 x{" "}
                                        {
                                          element.structure_rooms.filter(
                                            (str_room) =>
                                              str_room._id === room.room_id
                                          )[0].name
                                        }
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {element.meals.length > 0 && (
                                  <div className="flex w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "Pasti:",
                                        id: "SD9Wub",
                                      })}
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.meals.map((meal) => (
                                        <div>{meal}</div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                                {!element.start_date && element.message && (
                                  <div className="flex w-full md:h-8 ">
                                    <div className="font-semibold">
                                      {intl.formatMessage({
                                        defaultMessage: "N° di persone:",
                                        id: "iR/Jy5",
                                      })}
                                    </div>
                                    <div className="flex flex-col ml-4 text-primary">
                                      {element.guests_number.adults ? (
                                        <div>
                                          {element.guests_number.adults}{" "}
                                          {intl.formatMessage({
                                            defaultMessage: "adulti",
                                            id: "HR89WG",
                                          })}
                                        </div>
                                      ) : null}
                                      {element.guests_number.children ? (
                                        <>
                                          <span className="md:hidden">
                                            &nbsp; -{" "}
                                          </span>
                                          <div>
                                            {element.guests_number.children}{" "}
                                            {intl.formatMessage({
                                              defaultMessage: "bambini",
                                              id: "ImjG1h",
                                            })}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                )}
                              </div>

                              {!element.is_valid ? (
                                <div className="flex items-center font-bold text-yellow-500 gap-x-2">
                                  <FontAwesomeIcon
                                    className="text-xl"
                                    icon={faExclamationTriangle}
                                  />
                                  <p>
                                    {intl.formatMessage({
                                      defaultMessage:
                                        "Struttura non più disponibile nel periodo selezionato",
                                      id: "l4+LAU",
                                    })}
                                  </p>
                                </div>
                              ) : (
                                <div className="flex items-center justify-between w-full">
                                  <div className="text-lg font-bold">
                                    {intl.formatMessage({
                                      defaultMessage: "Prezzo:",
                                      id: "EGV7fv",
                                    })}
                                  </div>
                                  <div className="text-xl font-bold md:text-2xl">
                                    {Math.ceil(element.total_price).toFixed(0) +
                                      ",00"}{" "}
                                    €
                                  </div>
                                </div>
                              )}
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </div>
                  <div className="flex flex-col md:col-span-2 gap-y-6">
                    <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg gap-y-4">
                      {elements.map((element, index) => {
                        if (element.experience) {
                          const experience = element.experience;
                          return (
                            <div className="flex items-center justify-between gap-x-2">
                              <div className="flex w-8/12 fle-col">
                                <div>
                                  {experience.name},{" "}
                                  {element.guests_number.adults}{" "}
                                  {intl.formatMessage({
                                    defaultMessage: "adulti",
                                    id: "HR89WG",
                                  })}
                                  {element.guests_number.children
                                    ? intl.formatMessage(
                                        {
                                          defaultMessage:
                                            ", {childNumber} bambini",
                                          id: "07sPCH",
                                        },
                                        {
                                          childNumber:
                                            element.guests_number.children,
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                              <div className="font-bold">
                                {Math.ceil(element.total_price).toFixed(0) +
                                  ",00"}{" "}
                                €
                              </div>
                            </div>
                          );
                        }
                        if (element.structure) {
                          const structure = element.structure;
                          return (
                            <div className="flex items-center justify-between gap-x-2">
                              <div className="flex w-7/12 fle-col">
                                <div>
                                  {structure.name},{" "}
                                  {element.guests_number.adults}{" "}
                                  {intl.formatMessage({
                                    defaultMessage: "adulti",
                                    id: "HR89WG",
                                  })}
                                  {element.guests_number.children
                                    ? intl.formatMessage(
                                        {
                                          defaultMessage:
                                            ", {childNumber} bambini",
                                          id: "07sPCH",
                                        },
                                        {
                                          childNumber:
                                            element.guests_number.children,
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                              <div className="font-bold">
                                {Math.ceil(element.total_price).toFixed(0) +
                                  ",00"}{" "}
                                €
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    <div
                      ref={ref}
                      className="flex flex-col p-6 bg-white rounded-lg shadow-lg gap-y-4"
                    >
                      <div className="flex items-end justify-between gap-x-2">
                        <div className="flex w-6/12 fle-col">
                          <div className="font-semibold">
                            {intl.formatMessage({
                              defaultMessage: "Totale:",
                              id: "ilfY6d",
                            })}
                          </div>
                        </div>
                        <div className="font-bold">
                          {Math.ceil(total).toFixed(0) + ",00"} €
                        </div>
                      </div>
                      <div className="border-b" />
                      <Link
                        to="/"
                        className="w-full text-center px-4 py-2 text-lg font-bold text-primary border rounded-[30px] border-primary hover:bg-gray-100"
                      >
                        {intl.formatMessage({
                          defaultMessage: "Continua ad esplorare",
                          id: "1RNBW8",
                        })}
                      </Link>
                      {!isCreatingSession ? (
                        <button
                          onClick={() =>
                            allElementValid ? createSession() : notifyInvalid()
                          }
                          className="w-full px-4 py-2 text-lg font-bold border rounded-[30px] border-primary bg-primary text-white hover:bg-secondary"
                        >
                          {intl.formatMessage({
                            defaultMessage: "Procedi all'acquisto",
                            id: "+6RlaJ",
                          })}
                        </button>
                      ) : (
                        <button className="w-full px-4 py-2 text-lg font-bold border rounded-[30px] border-primary bg-primary text-white">
                          <UiduSpinner size="small" invertColor={true} />
                        </button>
                      )}
                    </div>
                    <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg gap-y-4">
                      <div className="font-bold">
                        {intl.formatMessage({
                          defaultMessage: "FAQ",
                          id: "W8nHSd",
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="flex items-center justify-center h-96">
                      <UiduSpinner size="medium" className="text-primary" />
                    </div>
                  ) : (
                    <p className="mt-4 text-xl text-gray-500">
                      {intl.formatMessage({
                        defaultMessage:
                          "Il tuo carrello è vuoto, aggiungi dei prodotti per visualizzarli",
                        id: "NjU77o",
                      })}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <motion.div
            id="fixed-section"
            className="fixed bottom-0 right-0 z-40 w-full p-4 pb-5 bg-white shadow-inner md:hidden"
            initial={{ y: 100, opacity: 0 }}
            animate={controls}
            transition={{ duration: 0.5 }}
          >
            <p className="font-bold text-primary">
              {intl.formatMessage({
                defaultMessage: "Totale:",
                id: "ilfY6d",
              })}{" "}
              {total ? Math.ceil(total).toFixed(0) + ",00" : "0.00"} €
            </p>
            <div className="flex w-full mt-2 gap-x-4">
              <button
                className="w-full py-2 text-white rounded-full bg-primary"
                onClick={() =>
                  allElementValid ? createSession() : notifyInvalid()
                }
              >
                {intl.formatMessage({
                  defaultMessage: "Procedi all'acquisto",
                  id: "+6RlaJ",
                })}
              </button>
              {/* <Link
                to="/"
                className="w-full py-2 text-center border rounded-full text-primary border-primary"
              >
                Continua ad esplorare
              </Link> */}
            </div>
          </motion.div>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce}
          />
        </div>
      </main>
      <Footer />
    </div>
  );
}
