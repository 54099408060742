import React, { useState } from "react";
import TagsInput from "react-tagsinput";

const TagsInputWithButton = ({ value, onChange, placeholder, id }) => {
  const [currentInput, setCurrentInput] = useState("");
  const [isWriting, setIsWriting] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());

  const handleAddTag = () => {
    if (currentInput.trim() !== "") {
      onChange([...value, currentInput.trim()]);
      setCurrentInput("");
      setRandomKey(Math.random());
    }
  };

  return (
    <div>
      <TagsInput
        key={randomKey}
        value={value}
        onChange={(tags) => {
          setIsWriting(false);
          onChange(tags);
        }}
        inputValue={currentInput}
        inputProps={{
          placeholder: placeholder,
          onInput: (e) => {
            setIsWriting(true);
            setCurrentInput(e.target.value);
          },
          onBlur: () => setIsWriting(false),
        }}
        id={id}
      />
      <div className="flex justify-end md:hidden">
        <button
          className="self-end px-4 py-1 mt-2 text-white rounded-lg bg-primary"
          onClick={handleAddTag}
        >
          Aggiungi
        </button>
      </div>
      {isWriting && (
        <div className="hidden w-full px-1 md:block">
          <div className="relative w-full px-4 pt-1 pb-2 -mt-px bg-white rounded-b-lg shadow-lg">
            <p className="text-sm text-slate-600">
              Premi invio per aggiungere un elemento
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TagsInputWithButton;
