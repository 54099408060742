import {
  faQuestionCircle,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsisH,
  faExclamationTriangle,
  faMinus,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import Tooltip from "@uidu/tooltip";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import modifica from "../../assets/icons/MODIFICA.svg";
import BaseComponent from "../BaseComponent";
import ModalPrice from "../ModalPrice";
import PopUp from "../PopUp";

function UpdateAvailabilityCard({
  room,
  value,
  structure,
  handleClick,
  bookingsForTheDay,
}) {
  const haveReservation = bookingsForTheDay.find(
    (booking) => booking.room_id === room._id && !booking.user_id
  );
  const freeRooms =
    room.quantity -
    (bookingsForTheDay?.filter(
      (booking) => booking?.room_id === room._id && booking.user_id
    )?.length || 0);

  const [openModalPrice, setOpenModalPrice] = useState(false);
  const [price, setPrice] = useState(null);
  const [prices, setPrices] = useState(room?.prices);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentAmount, setCurrentAmount] = useState(
    freeRooms -
      (haveReservation ? haveReservation.rooms_number_reserved || 0 : 0)
  );

  const deletePrice = async () => {
    const token = localStorage.getItem("token");
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/priceDelete/${price._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setPrices(prices.filter((p) => p._id !== price._id));
        setPrice(null);
        setOpenDelete(false);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  useEffect(() => {
    console.log(bookingsForTheDay, "bookingsForTheDay");
    setCurrentAmount(
      freeRooms - (haveReservation ? haveReservation.rooms_number_reserved : 0)
    );
  }, [bookingsForTheDay]);

  return (
    <div className="flex flex-col w-full py-8 gap-y-2">
      <div className="flex justify-between w-full pb-4 text-primary">
        <h3 className="text-xl font-semibold">{room.name}</h3>
        <div className="flex items-center gap-x-4">
          <button
            className="flex items-center px-4 py-1 text-white rounded-md gap-x-2 bg-primary"
            onClick={() => setOpenModalPrice(true)}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Aggiungi prezzo</span>
          </button>
          <PopUp
            icon={
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-xs text-white"
              />
            }
            items={
              <>
                <Link
                  className="flex items-center w-full h-8 px-6 py-5 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                  to={`/proposeStructure/${structure._id}/room${room._id}`}
                >
                  <img src={modifica} alt="modifica" className="w-6 h-6 pr-2" />
                  <div>Modifica camera</div>
                </Link>
              </>
            }
          />
        </div>
      </div>
      <div className="border rounded-[25px] border-primary">
        <div className="flex flex-col w-full p-4 pb-0 gap-y-4 md:gap-y-0 md:grid md:items-center md:grid-cols-2 md:pl-6 ">
          <div className="font-bold md:border-r border-primary">
            Posti disponibili{" "}
          </div>
          <div className="flex items-center justify-between w-full md:justify-end gap-x-4">
            <p className="text-sm">
              {value.toLocaleDateString("it", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </p>
            <div className="items-center justify-between ml-4 flex gap-x-4 w-full md:w-[168px]">
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="minus"
                onClick={(e) => {
                  if (currentAmount > 0) {
                    setCurrentAmount(currentAmount - 1);
                    handleClick(e, room);
                  }
                }}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="text-center ">
                {currentAmount}
                {" su "}
                {freeRooms}
              </div>
              <button
                className="flex items-center justify-center w-6 h-6 text-xs border rounded-full text-primary border-primary"
                name="plus"
                onClick={(e) => {
                  if (currentAmount < freeRooms) {
                    setCurrentAmount(currentAmount + 1);
                    handleClick(e, room);
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
            <div className="md:hidden" />
          </div>
          <div className="flex items-center w-full col-span-2">
            <div className="my-4 text-lg font-bold text-primary">Prezzi :</div>
          </div>
        </div>
        <div className="mx-4 -mt-4 md:-mx-2">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase sm:pl-0"
                  >
                    Dal
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase"
                  >
                    Al
                  </th>
                  <th
                    scope="col"
                    className="w-full px-3 py-3 text-xs font-medium tracking-wide text-left text-gray-500 uppercase text-end"
                  >
                    <span className="md:pr-[201px]">Prezzo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                <tr>
                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-500 whitespace-nowrap sm:pl-0">
                    <div className="flex items-center justify-between w-16 md:w-32 gap-x-2">
                      <span>--/--/--</span>
                      <Tooltip
                        content="Prezzo standard senza fee, verrà applicato questo prezzo se non è stata impostato un prezzo personalizzato per il periodo."
                        position="left"
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          className="pr-4 cursor-pointer text-primary"
                        />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    <div className="flex items-center justify-between w-16 md:w-32 gap-x-2">
                      <span>--/--/--</span>
                      <Tooltip
                        content="Prezzo standard senza fee, verrà applicato questo prezzo se non è stata impostato un prezzo personalizzato per il  periodo."
                        position="left"
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          className="pr-4 cursor-pointer text-primary"
                        />
                      </Tooltip>
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                    <div className="flex items-center justify-end gap-x-6">
                      <div className="flex items-center justify-between gap-x-4 w-[120px] md:w-[250px]">
                        <div className="items-center justify-center gap-x-4">
                          {(room.price / 100).toFixed(2)} €
                        </div>
                        <Tooltip content="Modifica il prezzo" position="left">
                          <Link
                            to={`/proposeStructure/${structure._id}/room${room._id}`}
                            className="flex items-center p-2 bg-gray-200 rounded-md text-escoBlack gap-x-2"
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </Link>
                        </Tooltip>
                      </div>
                    </div>
                  </td>
                </tr>
                {prices.map((price) => (
                  <tr key={price._id}>
                    <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-500 whitespace-nowrap sm:pl-0">
                      {dayjs(price.from_date).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      {dayjs(price.to_date).format("DD/MM/YYYY")}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                      <div className="flex items-center justify-end gap-x-6">
                        <div className="flex items-center justify-between gap-x-4 w-[120px] md:w-[250px]">
                          <div className="items-center justify-center gap-x-4">
                            {(price.price / 100).toFixed(2)} €
                          </div>
                          <div className="flex items-center justify-between gap-x-4">
                            <Tooltip
                              content="Modifica il prezzo"
                              position="left"
                            >
                              <button
                                className="flex items-center p-2 bg-gray-200 rounded-md text-escoBlack gap-x-2"
                                onClick={() => {
                                  setPrice(price);
                                  setOpenModalPrice(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faPencil} />
                              </button>
                            </Tooltip>
                            <Tooltip
                              content="Elimina il prezzo"
                              position="left"
                            >
                              <button
                                className="flex items-center p-2 bg-gray-200 rounded-md text-escoBlack gap-x-2"
                                onClick={() => {
                                  setPrice(price);
                                  setOpenDelete(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashCan} />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalTransition>
        {openDelete && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
            <Modal
              onClose={() => setOpenDelete(false)}
              className="mt-0 md:mt-20"
              shouldCloseOnOverlayClick={false}
            >
              <ModalHeader>
                <div className="flex items-center gap-x-4">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="w-6 h-6 text-yellow-500"
                  />
                  <h3 className="text-2xl">Attenzione</h3>
                </div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDelete(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </ModalHeader>
              <ModalBody>
                <div className="text-gray-400 ">
                  Sei sicuro di voler eliminare il prezzo selezionato?
                  <br /> Questa azione è irreversibile.
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
                  <button
                    className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                    onClick={() => setOpenDelete(false)}
                  >
                    Annulla
                  </button>
                  <button
                    className="w-full h-10 text-sm text-white bg-red-500 rounded-lg md:w-28"
                    onClick={() => deletePrice()}
                  >
                    Elimina
                  </button>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        )}
      </ModalTransition>
      {openModalPrice && (
        <ModalPrice
          setOpenModal={setOpenModalPrice}
          relatedToId={room._id}
          relatedToType="room"
          price={price}
          setPrice={setPrice}
          setPrices={setPrices}
          prices={prices}
        />
      )}
    </div>
  );
}

export default function Availability({ structures }) {
  const [selectedStructure, setSelectedStructure] = useState(structures[0]);
  const animatedComponents = makeAnimated();
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState([]);

  const getRooms = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/roomsOfStructure/${selectedStructure._id}`
        )
        .then((res) => {
          setRooms(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getRoomAvailabilityForTheDay = async (date) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/roomsBookingsOnDate`, {
          structure_id: selectedStructure._id,
          rooms: rooms.map((room) => room._id),
          date: new Date(dayjs(date).format("YYYY-MM-DD")),
        })
        .then((res) => {
          setBookingsForTheDay(
            res.data.map((booking) => {
              return {
                ...booking,
                rooms_number_reserved: booking.rooms_number_reserved || 1,
              };
            })
          );
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (selectedStructure) {
      setLoading(true);
      getRooms();
    }
  }, [selectedStructure]);

  useEffect(() => {
    if (rooms.length > 0) {
      getRoomAvailabilityForTheDay(value);
    }
  }, [rooms]);

  const createReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservation`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => [
          ...bookingsForTheDay,
          res.data,
        ]);
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const updateReservation = async (data) => {
    const token = localStorage.getItem("token");
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservationUpdate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.map((booking) => {
            if (booking._id === data.id) {
              return {
                ...booking,
                rooms_number_reserved: data.rooms_number_reserved,
              };
            } else {
              return booking;
            }
          });
          return updatedBookingsForTheDay;
        });
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const deleteReservation = async (id) => {
    const token = localStorage.getItem("token");
    await axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/api/bookingRoomReservationDelete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setBookingsForTheDay((bookingsForTheDay) => {
          const updatedBookingsForTheDay = bookingsForTheDay.filter(
            (booking) => booking._id !== id
          );
          return updatedBookingsForTheDay;
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const handleClick = (e, room) => {
    e.preventDefault();
    const totalGuests = bookingsForTheDay
      .filter((booking) => booking.room_id === room._id)
      .reduce((acc, booking) => acc + booking.rooms_number_reserved, 0);
    const bookingId = bookingsForTheDay.find(
      (booking) => booking.room_id === room._id && !booking.user_id
    );
    if (totalGuests >= room.quantity && !bookingId) {
      return;
    }
    console.log(e.target.name, e.target.parentNode.name, e);
    if (
      e.target.name === "plus" ||
      e.target.parentNode.name === "plus" ||
      e.target.parentNode.parentNode.name === "plus"
    ) {
      if (bookingId) {
        if (bookingId.rooms_number_reserved > 1) {
          updateReservation({
            id: bookingId._id,
            rooms_number_reserved: bookingId.rooms_number_reserved - 1,
          });
        } else {
          deleteReservation(bookingId._id);
        }
      } else {
        console.log("bookingId not found");
      }
    } else {
      if (bookingId) {
        if (
          totalGuests < room.quantity &&
          bookingId.rooms_number_reserved < room.quantity
        ) {
          updateReservation({
            id: bookingId._id,
            rooms_number_reserved: bookingId.rooms_number_reserved + 1,
          });
        }
      } else {
        createReservation({
          structure_id: selectedStructure._id,
          room_id: room._id,
          start_date: new Date(dayjs(value).format("YYYY-MM-DD")),
          end_date: new Date(dayjs(value).add(1, "day").format("YYYY-MM-DD")),
        });
      }
    }
  };

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tua disponibilità
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <p>
          Verifica la disponibilità delle tue strutture e modificala a tuo
          piacimento a seconda delle tue esigenze.
        </p>
        {structures.length > 1 && (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Struttura</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // menuPortalTarget={document.body}
                // menuPosition="absolute"
                defaultValue={
                  selectedStructure && {
                    value: selectedStructure?._id,
                    label: selectedStructure?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={structures.map((structure) => ({
                  value: structure._id,
                  label: structure.name,
                }))}
                onChange={(value) => {
                  setSelectedStructure(
                    structures.find(
                      (structure) => structure._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getRoomAvailabilityForTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
          />
        </div>
        <div className="flex flex-col w-full mt-6 divide-y">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {rooms.length > 0 && (
                <>
                  {rooms.map((room) => (
                    <UpdateAvailabilityCard
                      key={`${room._id}-${value}`}
                      room={room}
                      value={value}
                      structure={selectedStructure}
                      handleClick={handleClick}
                      bookingsForTheDay={bookingsForTheDay}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
