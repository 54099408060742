import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ProposeStructureContext } from "../../pages/ProposeStructure";
import BaseComponent from "../BaseComponent";
import TagsInputWithButton from "../utils/TagsInputWithButton";

export default function StructureDetails() {
  const animatedComponents = makeAnimated();
  const { id } = useParams();

  const { structure, setStructure, setErrors } = useContext(
    ProposeStructureContext
  );
  const [selectedLenguages, setSelectedLenguages] = useState(
    structure?.lenguages || null
  );
  const [selectedAccessibility, setSelectedAccessibility] = useState(
    structure?.accessibility || null
  );
  const [extraAccessibility, setExtraAccessibility] = useState(
    structure?.extra_accessibility || []
  );

  const updateStructure = async () => {
    const token = localStorage.getItem("token");
    const currentErrors = [
      { id: "lenguages", error: null },
      { id: "description", error: null },
      { id: "extra_accessibility", error: null },
    ];

    if (document.getElementById("description").value.length < 100) {
      currentErrors[1].error =
        "La descrizione deve essere lunga almeno 100 caratteri";
    }

    if (selectedLenguages.length === 0 || !selectedLenguages) {
      currentErrors[0].error = "Seleziona almeno una lingua";
    }

    if (
      selectedAccessibility.includes("altro") &&
      (!extraAccessibility || extraAccessibility.length === 0)
    ) {
      currentErrors[2].error =
        "Devi specificare le accessibilità indicate come 'Altro'";
    }

    setErrors(currentErrors);

    if (
      currentErrors[0].error ||
      currentErrors[1].error ||
      currentErrors[2].error
    )
      return;

    const data = {
      lenguages: selectedLenguages,
      accessibility: selectedAccessibility,
      description: document.getElementById("description").value,
      ...(extraAccessibility.length > 0 && {
        extra_accessibility: extraAccessibility,
      }),
    };

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/structure/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setStructure(res.data.structure);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento della struttura");
      });
  };

  useEffect(() => {
    updateStructure();
  }, []);

  useEffect(() => {
    if (
      structure &&
      JSON.stringify(selectedLenguages) !== JSON.stringify(structure?.lenguages)
    ) {
      updateStructure();
    }
    if (
      structure &&
      JSON.stringify(selectedAccessibility) !==
        JSON.stringify(structure?.accessibility)
    ) {
      updateStructure();
    }
    if (
      structure &&
      JSON.stringify(extraAccessibility) !==
        JSON.stringify(structure?.extra_accessibility)
    ) {
      updateStructure();
    }
  }, [selectedLenguages, structure, selectedAccessibility, extraAccessibility]);

  return (
    <BaseComponent title="Andiamo nel dettaglio">
      <div className="flex flex-col px-4 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md md:px-10 gap-y-6">
        {structure ? (
          <>
            <p className="font-semibold">Descrizione completa</p>
            <textarea
              className=" p-4 md:p-6 border-2 border-gray-200 resize-none rounded-[20px] rounded-[40px] "
              placeholder="Descrivi la tua struttura"
              rows={8}
              id="description"
              defaultValue={structure.description}
              onChange={() => updateStructure()}
            ></textarea>
            {/* {errors[1].error && (
              <p className="-mt-2 text-sm text-gray-400">{errors[1].error}</p>
            )} */}
            <div>
              <div className="flex flex-col items-center w-full gap-4 md:flex-row">
                <p className="w-full font-semibold md:w-1/4">
                  Seleziona lingue parlate
                </p>
                <div className="w-full md:w-3/4">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    menuPortalTarget={document.body}
                    menuPosition="absolute"
                    defaultValue={
                      structure.lenguages &&
                      structure.lenguages.map((l) => ({
                        value: l,
                        label: l.charAt(0).toUpperCase() + l.slice(1),
                      }))
                    }
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: "2px solid rgb(229 231 235)",
                        height: "35px",
                      }),
                      multiValue: (provided) => ({
                        ...provided,
                        backgroundColor: "#246155",
                        color: "white",
                      }),
                      multiValueLabel: (provided) => ({
                        ...provided,
                        color: "white",
                      }),
                      multiValueRemove: (provided, state) => ({
                        ...provided,
                        color: "white",
                        ":hover": {
                          backgroundColor: "#328575",
                        },
                      }),
                    }}
                    options={[
                      {
                        value: "italiano",
                        label: "Italiano",
                      },
                      {
                        value: "inglese",
                        label: "Inglese",
                      },
                      {
                        value: "francese",
                        label: "Francese",
                      },
                      {
                        value: "spagnolo",
                        label: "Spagnolo",
                      },
                      {
                        value: "tedesco",
                        label: "Tedesco",
                      },
                      {
                        value: "altro",
                        label: "Altro",
                      },
                    ]}
                    onChange={(value) => {
                      setSelectedLenguages(value.map((v) => v.value));
                    }}
                  />
                </div>
              </div>
              {/* {errors[0].error && (
                <div className="flex items-center w-full gap-x-4">
                  <div className="w-1/4" />
                  <p className="w-3/4 mt-2 text-sm text-gray-400">
                    {errors[0].error}
                  </p>
                </div>
              )} */}
            </div>
            <div className="flex flex-col items-center w-full gap-4 md:flex-row">
              <p className="w-full font-semibold md:w-1/4">Accessibilità</p>
              <div className="w-full md:w-3/4">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  menuPortalTarget={document.body}
                  menuPosition="absolute"
                  defaultValue={structure?.accessibility.map((l) => ({
                    value: l,
                    label:
                      l === "visivi"
                        ? "Ausili visivi: segnaletica tattile"
                        : l === "sedia"
                        ? "Accessibile in sedia a rotelle"
                        : "Altro",
                  }))}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "2px solid rgb(229 231 235)",
                      height: "35px",
                      width: "100%",
                    }),
                    multiValue: (provided) => ({
                      ...provided,
                      backgroundColor: "#246155",
                      color: "white",
                    }),
                    multiValueLabel: (provided) => ({
                      ...provided,
                      color: "white",
                    }),
                    multiValueRemove: (provided, state) => ({
                      ...provided,
                      color: "white",
                      ":hover": {
                        backgroundColor: "#328575",
                      },
                    }),
                  }}
                  options={[
                    {
                      value: "visivi",
                      label: "Ausili visivi: segnaletica tattile",
                    },
                    {
                      value: "sedia",
                      label: "Accessibile in sedia a rotelle",
                    },
                    {
                      value: "altro",
                      label: "Altro",
                    },
                  ]}
                  onChange={(value) => {
                    setSelectedAccessibility(value.map((v) => v.value));
                  }}
                />
              </div>
            </div>
            {selectedAccessibility.includes("altro") && (
              <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-4">
                <p className="font-semibold">
                  Specifica le accessibilità della tua struttura
                </p>
                <div className="w-full col-span-3">
                  <TagsInputWithButton
                    value={extraAccessibility}
                    onChange={(tags) => {
                      setExtraAccessibility(tags);
                    }}
                    placeholder="Specifica le accessibilità"
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
