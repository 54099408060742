import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import TimePicker from "react-time-picker";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";

export default function Recurrency() {
  const { id } = useParams();

  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [selectedWeekDays, setSelectedWeekDays] = useState(
    experience?.data_info?.week_days || []
  );
  const [timeValue, onChange] = useState(
    experience?.data_info?.start_time || ""
  );
  const [selectedOption, setSelectedOption] = useState(
    experience?.data_info?.recurrency_type || null
  );
  const animatedComponents = makeAnimated();
  const [isRecurrent, setIsRecurrent] = useState(experience.is_recurrent);
  const daysOfWeek = [
    "lunedi",
    "martedi",
    "mercoledi",
    "giovedi",
    "venerdi",
    "sabato",
    "domenica",
  ];
  const [weekDaysStartTime, setWeekDaysStartTime] = useState(
    experience?.data_info?.week_days_start_time
      ? experience?.data_info?.week_days_start_time.reduce((acc, curr) => {
          acc[curr.day] = curr.time;
          return acc;
        }, {})
      : {}
  );
  const [currentTimes, setCurrentTimes] = useState({});
  const daysToRender = selectedOption === "day" ? daysOfWeek : selectedWeekDays;

  const updateExperience = async () => {
    const token = localStorage.getItem("token");
    let currentError = [{ error: null }];
    let data = {};
    console.log("weekDaysStartTime", weekDaysStartTime);

    if (isRecurrent) {
      if (selectedOption === null) {
        currentError = [{ error: "Devi selezionare una ricorrenza" }];
        setErrors(currentError);
        return;
      }
      if (
        selectedOption === "week" &&
        (selectedWeekDays === null || selectedWeekDays.length === 0)
      ) {
        currentError = [
          { error: "Devi selezionare almeno un giorno della settimana" },
        ];
        setErrors(currentError);
        return;
      }
      // if (timeValue === "") {
      //   currentError = [{ error: "Devi selezionare un orario di inizio" }];
      //   setErrors(currentError);
      //   return;
      // }
      // we need to check that for every day of the week there is at least one start time
      if (selectedOption === "week") {
        for (const day of selectedWeekDays) {
          if (!weekDaysStartTime[day] || weekDaysStartTime[day].length === 0) {
            currentError = [
              {
                error: `Devi selezionare almeno un orario di inizio per il giorno ${day}`,
              },
            ];
            setErrors(currentError);
            return;
          }
        }
      }
      if (selectedOption === "day") {
        for (const day of daysOfWeek) {
          if (!weekDaysStartTime[day] || weekDaysStartTime[day].length === 0) {
            currentError = [
              {
                error: `Devi selezionare almeno un orario di inizio per il giorno ${day}`,
              },
            ];
            setErrors(currentError);
            return;
          }
        }
      }

      setErrors(currentError);

      data = {
        data_info: {
          start_time: timeValue,
          recurrency_type: selectedOption,
          week_days: selectedWeekDays,
          week_days_start_time: Object.keys(weekDaysStartTime).map((day) => {
            return {
              day,
              time: weekDaysStartTime[day],
            };
          }),
        },
      };
    } else {
      if (document.getElementById("startDate").value === "") {
        currentError = [{ error: "Devi selezionare una data di inizio" }];
        setErrors(currentError);
        return;
      }
      if (dayjs(document.getElementById("startDate").value).isBefore(dayjs())) {
        currentError = [
          { error: "La data di inizio non può essere nel passato" },
        ];
        setErrors(currentError);
        return;
      }
      if (timeValue === "") {
        currentError = [{ error: "Devi selezionare un orario di inizio" }];
        setErrors(currentError);
        return;
      }

      setErrors(currentError);

      data = {
        data_info: {
          start_date: document.getElementById("startDate").value,
          start_time: timeValue,
        },
      };
      console.log(data, "data");
    }

    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setExperience(res.data.experience);
      })
      .catch(() => {
        alert("Errore durante l'aggiornamento dell'esperienza");
      });
  };

  useEffect(() => {
    if (experience) {
      updateExperience();
    }
  }, []);

  useEffect(() => {
    if (experience) {
      if (experience.data_info.recurrency_type !== selectedOption) {
        updateExperience();
      }
      if (experience.data_info.week_days !== selectedWeekDays) {
        updateExperience();
      }
      if (timeValue && timeValue !== experience.data_info.start_time) {
        updateExperience();
      }
      if (
        weekDaysStartTime &&
        JSON.stringify(weekDaysStartTime) !==
          JSON.stringify(
            experience?.data_info?.week_days_start_time.reduce((acc, curr) => {
              acc[curr.day] = curr.time;
              return acc;
            }, {})
          )
      ) {
        updateExperience();
      }
    }
  }, [selectedOption, selectedWeekDays, timeValue, weekDaysStartTime]);

  const validateTime = (time) => {
    // Simple validation to check if time is in HH:MM format
    const timePattern = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
    return timePattern.test(time);
  };

  const handleTimeChange = (day, time) => {
    setCurrentTimes((prevState) => ({
      ...prevState,
      [day]: time,
    }));
  };

  const handleTimeAdd = (day, time) => {
    if (!validateTime(time)) {
      setErrors((prevErrors) => [
        ...prevErrors,
        `Orario non valido per il giorno ${day}`,
      ]);
      return;
    }
    setWeekDaysStartTime((prevState) => ({
      ...prevState,
      [day]: [...(prevState[day] || []), time],
    }));
    setCurrentTimes((prevState) => ({
      ...prevState,
      [day]: "",
    }));
    setErrors([]);
  };

  const handleTimeRemove = (day, index) => {
    setWeekDaysStartTime((prevState) => ({
      ...prevState,
      [day]: prevState[day].filter((_, i) => i !== index),
    }));
  };

  const handleKeyDown = (day, event) => {
    if (event.key === "Enter") {
      handleTimeAdd(day, currentTimes[day]);
    }
  };

  useEffect(() => {
    if (selectedOption !== experience.data_info.recurrency_type) {
      setWeekDaysStartTime({});
    }
    setCurrentTimes({});
  }, [selectedOption]);

  return (
    <BaseComponent title={experience.isRecurrent ? "Ricorrenza" : "Data e ora"}>
      <div className="flex flex-col justify-between min-h-[400px] overflow-y-auto bg-gray-100 rounded-md px-4 md:px-10 py-4 my-4">
        {experience ? (
          <>
            <div className="flex flex-col gap-y-4">
              {!isRecurrent ? (
                <>
                  <p className="font-semibold">Data inizio</p>
                  <input
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                    type="date"
                    id="startDate"
                    onChange={() => updateExperience()}
                    defaultValue={
                      experience.data_info.start_date
                        ? new Date(experience.data_info.start_date)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                  />
                  <p className="font-semibold">Ora inizio</p>
                  <div className="w-full bg-white h-[35px] shadow border rounded border-gray-200 pl-2">
                    <TimePicker
                      id="startTime"
                      onChange={onChange}
                      value={timeValue}
                      disableClock={true}
                      name="startTime"
                      className="w-full h-full"
                    />
                  </div>
                </>
              ) : (
                <>
                  <p className="font-semibold">Ripeti ogni</p>
                  <div className="w-full">
                    <Select
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      menuPortalTarget={document.body}
                      menuPosition="absolute"
                      name="recurrency"
                      defaultValue={{
                        label:
                          selectedOption === "day" ? "Giorno" : "Settimana",
                        value: selectedOption,
                      }}
                      onChange={(e) => setSelectedOption(e.value)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          border: "2px solid rgb(229 231 235)",
                        }),
                        multiValue: (provided) => ({
                          ...provided,
                          backgroundColor: "#246155",
                          color: "white",
                        }),
                        multiValueLabel: (provided) => ({
                          ...provided,
                          color: "white",
                        }),
                        multiValueRemove: (provided, state) => ({
                          ...provided,
                          color: "white",
                          ":hover": {
                            backgroundColor: "#328575",
                          },
                        }),
                      }}
                      options={[
                        { value: "day", label: "Giorno" },
                        { value: "week", label: "Settimana" },
                      ]}
                    />
                  </div>
                  {selectedOption === "week" && (
                    <>
                      <p className="font-semibold">Giorni della settimana</p>
                      <div className="w-full">
                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          menuPortalTarget={document.body}
                          menuPosition="absolute"
                          isMulti
                          name="weekDays"
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              border: "2px solid rgb(229 231 235)",
                            }),
                            multiValue: (provided) => ({
                              ...provided,
                              backgroundColor: "#246155",
                              color: "white",
                            }),
                            multiValueLabel: (provided) => ({
                              ...provided,
                              color: "white",
                            }),
                            multiValueRemove: (provided, state) => ({
                              ...provided,
                              color: "white",
                              ":hover": {
                                backgroundColor: "#328575",
                              },
                            }),
                          }}
                          defaultValue={selectedWeekDays.map((day) => {
                            return {
                              label: day.charAt(0).toUpperCase() + day.slice(1),
                              value: day,
                            };
                          })}
                          options={[
                            { value: "lunedi", label: "Lunedi" },
                            { value: "martedi", label: "Martedi" },
                            { value: "mercoledi", label: "Mercoledi" },
                            { value: "giovedi", label: "Giovedi" },
                            { value: "venerdi", label: "Venerdi" },
                            { value: "sabato", label: "Sabato" },
                            { value: "domenica", label: "Domenica" },
                          ]}
                          onChange={(value) => {
                            setSelectedWeekDays(value.map((v) => v.value));
                          }}
                        />
                      </div>
                    </>
                  )}
                  {selectedOption && (
                    <>
                      {selectedOption === "day" ? (
                        <div className="flex flex-col">
                          <p className="font-semibold">Orari inizio </p>
                          <span className="text-xs">
                            ( E' possibile aggiungere più orari per lo stesso
                            giorno )
                          </span>
                        </div>
                      ) : (
                        <>
                          {selectedOption === "week" &&
                            selectedWeekDays.length > 0 && (
                              <div className="flex flex-col">
                                <p className="font-semibold">Orari inizio </p>
                                <span className="text-xs">
                                  ( E' possibile aggiungere più orari per lo
                                  stesso giorno )
                                </span>
                              </div>
                            )}
                        </>
                      )}
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {daysToRender.map((day) => (
                          <div key={day} className="flex flex-col mb-2 gap-y-2">
                            <div className="flex items-center gap-x-2">
                              <p className="font-semibold">{day} :</p>
                              {weekDaysStartTime[day] &&
                                weekDaysStartTime[day].map((time, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center px-2 py-0.5 bg-gray-200 rounded"
                                  >
                                    <p className="text-sm">{time}</p>
                                    <button
                                      className="ml-2 text-primary"
                                      onClick={() =>
                                        handleTimeRemove(day, index)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faXmark} />
                                    </button>
                                  </div>
                                ))}
                            </div>
                            <div className="w-full bg-white h-[35px] shadow border rounded border-gray-200 pl-2">
                              <TimePicker
                                id={`startTime-${day}`}
                                onChange={(time) => handleTimeChange(day, time)}
                                onKeyDown={(event) => handleKeyDown(day, event)}
                                disableClock={true}
                                name={`startTime-${day}`}
                                value={currentTimes[day] || ""}
                                className="w-full h-full"
                              />
                            </div>
                            <div className="flex justify-end md:hidden">
                              <button
                                className="px-4 py-1 mt-2 text-white rounded-lg text-primary bg-primary"
                                onClick={() =>
                                  handleTimeAdd(day, currentTimes[day])
                                }
                              >
                                Aggiungi Orario
                              </button>
                            </div>
                            <p className="hidden text-sm text-gray-500 md:block">
                              Premi Invio per aggiungere un'orario
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
