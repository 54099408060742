import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";

export default function EditNotifications({
  user,
  setOpenEditNotifications,
  setUser,
}) {
  const intl = useIntl();
  const [whatsappNotifications, setWhatsappNotifications] = useState(
    user.whatsapp_notifications || false
  );
  const [phone, setPhone] = useState(user.whatsapp_number || "");

  const [errors, setErrors] = useState([
    { id: "whatsapp_number", error: null },
  ]);

  const updateUser = async () => {
    const token = localStorage.getItem("token");

    let currentErrors = [{ id: "whatsapp_number", error: null }];

    if (whatsappNotifications) {
      if (phone.length < 5 || phone.length > 12) {
        currentErrors.find((e) => e.id === "whatsapp_number").error =
          intl.formatMessage({
            defaultMessage: "Inserisci un numero di telefono valido",
            id: "geGKxs",
          });
      }

      setErrors(currentErrors);

      if (currentErrors.find((e) => e.error === null)) {
        const data = {
          whatsapp_notifications: whatsappNotifications,
          whatsapp_number: phone,
        };

        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user._id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setUser(res.data);
            setOpenEditNotifications(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else if (user.whatsapp_notifications !== whatsappNotifications) {
      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/user/resetWhatsappNotifications/${user._id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUser(res.data);
          setOpenEditNotifications(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="flex flex-col justify-between w-full  min-h-[400px] gap-y-4">
      <div className="flex flex-col w-full py-4 gap-y-4">
        <div className="flex items-center w-full mb-2 text-xl gap-x-2 text-primary">
          <FontAwesomeIcon icon={faWhatsapp} />
          <h2 className="font-bold">Whatsapp</h2>
        </div>
        <label
          htmlFor="whatsappNotifications"
          className="flex items-center text-base font-semibold gap-x-3"
        >
          <input
            type="checkbox"
            id="whatsappNotifications"
            name="whatsappNotifications"
            className="hidden"
            defaultChecked={false}
          />
          <div
            className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
            onClick={() => {
              setWhatsappNotifications((prev) => !prev);
            }}
          >
            {whatsappNotifications && (
              <FontAwesomeIcon className="h-3" icon={faCheck} />
            )}
          </div>
          Voglio abilitare le notifiche su WhatsApp
        </label>
        {whatsappNotifications && (
          <div className="relative flex flex-col w-full gap-y-2">
            <p className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Numero di telefono whatsapp",
                id: "RsQ6Qr",
              })}
            </p>
            <PhoneInput
              country="it"
              regions={"europe"}
              placeholder={"Numero whatsapp"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: "whatsapp_number",
                required: true,
                autoFocus: true,
              }}
              searchStyle={{
                width: "100%",
                height: "35px",
                border: "1px solid rgb(229 231 235)",
              }}
              inputStyle={{
                width: "100%",
                height: "35px",
                border: "1px solid rgb(229 231 235)",
                boxShadow:
                  "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              }}
              specialLabel=""
            />
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "whatsapp_number").error}
              </p>
            }
          </div>
        )}
      </div>
      <div className="flex items-end justify-end w-full mb-4 gap-x-8">
        <button
          className="flex items-center justify-center px-4 py-2 text-white bg-gray-400 rounded-md"
          type="button"
          onClick={() => setOpenEditNotifications(false)}
        >
          {intl.formatMessage({
            defaultMessage: "Annulla",
            id: "tfqu9h",
          })}
        </button>
        <button
          className="flex items-center justify-center px-4 py-2 text-white rounded-md bg-primary"
          type="button"
          onClick={() => updateUser()}
        >
          {intl.formatMessage({
            defaultMessage: "Salva",
            id: "eRxNJP",
          })}
        </button>
      </div>
    </div>
  );
}
