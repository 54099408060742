import { useLoadScript } from "@react-google-maps/api";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useParams } from "react-router-dom";
import { ProposeContext } from "../../pages/Propose";
import BaseComponent from "../BaseComponent";
import LoadingSpinnerPage from "../LoadingSpinnerPage";
import Map from "../Map";

export default function Location() {
  const { experience, setExperience, setErrors } = useContext(ProposeContext);
  const [address, setAddress] = useState("");
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
  });
  const { id } = useParams();

  const [currentAddress, setCurrentAddress] = useState({
    latitude: 45.7658776,
    longitude: 10.3767045,
  });

  const getAddress = async (id) => {
    const token = localStorage.getItem("token");

    await axios
      .get(`${process.env.REACT_APP_SERVER_URL}/api/address/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAddress(res.data.address);
        setCurrentAddress({
          latitude: res.data.latitude,
          longitude: res.data.longitude,
        });
      })
      .catch(() => {
        alert("Errore durante il recupero dell'indirizzo");
      });
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    const token = localStorage.getItem("token");

    console.log(results[0], "result");
    const addressData = {
      address: value,
      village:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "locality"
        )?.long_name ?? "",
      municipality:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "administrative_area_level_3"
        )?.long_name ?? "",
      province:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "administrative_area_level_2"
        )?.short_name ?? "",
      country:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "country"
        )?.long_name ?? "",
      postal_code:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "postal_code"
        )?.long_name ?? "",
      street_number:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "street_number"
        )?.long_name ?? "",
      route:
        results[0]?.address_components?.find(
          (component) => component.types[0] === "route"
        )?.long_name ?? "",
      latitude: latLng?.lat ?? null,
      longitude: latLng?.lng ?? null,
      place_id: results[0]?.place_id ?? null,
      formatted_address: results[0]?.formatted_address ?? null,
    };

    if (!addressData.route) {
      setErrors([{ error: "Errore: l'indirizzo non è abbastanza specifico" }]);
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/address/create`,
        addressData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        const data = {
          address: res.data._id,
        };
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/experience/edit/${id}`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setExperience(res.data.experience);
          })
          .catch(() => {
            alert("Errore durante la modifica dell'esperienza");
          });
      })
      .catch(() => {
        alert("Errore durante la creazione dell'indirizzo");
      });

    setAddress(value);
    setCurrentAddress({
      latitude: latLng.lat,
      longitude: latLng.lng,
    });
  };

  useEffect(() => {
    if (experience && experience.address) {
      setErrors([]);
      if (!experience.address.address) {
        getAddress(experience.address);
      } else {
        setAddress(experience.address.address);
        setCurrentAddress({
          latitude: experience.address.latitude,
          longitude: experience.address.longitude,
        });
      }
    } else if (address === "") {
      setErrors([
        { error: "Devi selezionare un indirizzo per la tua esperienza!" },
      ]);
    }
  }, [experience]);

  if (loadError) {
    return <div>Error loading script</div>;
  }

  if (!isLoaded) {
    return <LoadingSpinnerPage />;
  }

  return (
    <BaseComponent title="Dove vorresti organizzare la tua esperienza? ">
      <div className="flex h-[400px]  flex-col px-4 md:px-10 py-4 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-4">
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: "Inserisci dove vuoi organizzare l'esperienza",
                  className:
                    "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                })}
              />
              <div className="relative z-30 w-full bg-white rounded-b-lg shadow-lg">
                <div className="absolute top-0 left-0 w-full overflow-y-auto bg-gray-200 rounded-b-lg max-h-60">
                  {loading ? (
                    <div className="w-full px-3 py-1 bg-white">
                      <UiduSpinner size="small" />
                    </div>
                  ) : (
                    <>
                      {suggestions.map((suggestion) => {
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.id}
                            className="w-full px-3 py-1 bg-white cursor-pointer hover:bg-sky-50"
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {currentAddress && (
          <Map
            address={currentAddress}
            isLoaded={isLoaded}
            loadError={loadError}
          />
        )}
      </div>
    </BaseComponent>
  );
}
