import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import datiPersonali from "../../assets/icons/DATI_PERSONALI.svg";
import BaseComponent from "../BaseComponent";
import EditNotifications from "../EditNotifications";
import EditPassword from "../EditPassword";
import EditUser from "../EditUser";

export default function Account() {
  const [user, setUser] = useState(null);
  const [editInfo, setEditInfo] = useState(false);
  const [editNotification, setEditNotification] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const { id } = useParams();
  const intl = useIntl();

  async function GetUser() {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/api/user/${id}`)
        .then((res) => {
          console.log(res.data);
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    GetUser();
  }, []);

  return (
    <BaseComponent
      title="Gestione account"
      sidebar={
        editInfo && (
          <button
            onClick={() => setEditPassword(true)}
            className="flex items-center justify-between px-4 text-white rounded-md bg-primary"
          >
            {intl.formatMessage({
              defaultMessage: "Modifica password",
              id: "uFAk6V",
            })}
          </button>
        )
      }
    >
      <div className="flex flex-col px-4 md:px-10 md:py-6 my-4 overflow-y-auto bg-gray-100 rounded-md gap-y-4 min-h-[400px]">
        {user ? (
          <>
            {editPassword ? (
              <EditPassword setOpenEditPassword={setEditPassword} />
            ) : editInfo ? (
              <EditUser
                user={user}
                setOpenEditInfo={setEditInfo}
                setUser={setUser}
              />
            ) : editNotification ? (
              <EditNotifications
                user={user}
                setOpenEditNotifications={setEditNotification}
                setUser={setUser}
              />
            ) : (
              <>
                <div className="flex items-center w-full md:justify-between">
                  <div className="flex items-center gap-x-3">
                    <img
                      src={datiPersonali}
                      alt="dati personali"
                      className="w-[26px] md:w-6"
                    />
                    <h1 className="text-lg font-semibold md:text-xl text-primary">
                      {intl.formatMessage({
                        defaultMessage: "Informazioni contatto",
                        id: "rpF5xu",
                      })}
                    </h1>
                  </div>
                  <button
                    onClick={() => setEditInfo(true)}
                    className="hidden md:flex items-center justify-between px-4 py-1.5 text-white rounded-md bg-primary "
                  >
                    {intl.formatMessage({
                      defaultMessage: "Modifica informazioni",
                      id: "qtHWsh",
                    })}
                  </button>
                </div>
                <div className="flex flex-col w-full md:flex-row">
                  <div className="flex justify-center w-full py-2 md:justify-start md:w-1/4">
                    {user?.profile_pic ? (
                      <img
                        src={user?.profile_pic}
                        alt="profile"
                        className="w-32 h-32 rounded-[30px] xl:w-44 xl:h-44 object-cover"
                      />
                    ) : (
                      <div className="w-32 h-32 bg-gray-300 rounded-[30px] xl:w-44 xl:h-44"></div>
                    )}
                  </div>
                  <div className="flex flex-col w-full divide-y md:w-3/4 divide-primary">
                    <div className="flex flex-col gap-2 py-2 md:py-4 md:flex-row md:items-center">
                      <p className="text-lg font-semibold md:min-w-[250px]">
                        {intl.formatMessage({
                          defaultMessage: "Nome e cognome :",
                          id: "s7stnx",
                        })}
                      </p>
                      <p className="font-semibold md:ml-4 text-primary">
                        {user?.name} {user?.surname}
                      </p>
                    </div>
                    {(user.has_structures || user.has_experiences) && (
                      <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:py-4">
                        <p className="text-lg font-semibold md:min-w-[250px]">
                          {intl.formatMessage({
                            defaultMessage: "Nome visibile sul portale :",
                            id: "gI+K29",
                          })}
                        </p>
                        <p className="font-semibold md:ml-4 text-primary">
                          {user?.profile_name
                            ? user?.profile_name
                            : `${user?.name}`}
                        </p>
                      </div>
                    )}
                    <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:py-4">
                      <p className="text-lg font-semibold md:min-w-[250px]">
                        {intl.formatMessage({
                          defaultMessage: "Email :",
                          id: "8hLqoV",
                        })}
                      </p>
                      <p className="font-semibold md:ml-4 text-primary">
                        {user?.email}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:py-4">
                      <p className="text-lg font-semibold md:min-w-[250px]">
                        {intl.formatMessage({
                          defaultMessage: "Telefono :",
                          id: "6x/pj1",
                        })}
                      </p>
                      <p className="font-semibold md:ml-4 text-primary">
                        {user.phone_number && user?.phone_number !== "-1"
                          ? user?.phone_number.substring(2)
                          : ""}
                      </p>
                    </div>
                    <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:py-4">
                      <p className="text-lg font-semibold md:min-w-[250px]">
                        {intl.formatMessage({
                          defaultMessage: "Indirizzo :",
                          id: "+jgr3P",
                        })}
                      </p>
                      <p className="font-semibold md:ml-4 text-primary">
                        {user?.address?.formatted_address}
                      </p>
                    </div>
                    {(user.has_structures || user.has_experiences) && (
                      <div className="flex flex-col gap-2 py-2 md:flex-row md:items-center md:py-4">
                        <p className="text-lg font-semibold md:min-w-[250px]">
                          {intl.formatMessage({
                            defaultMessage: "Bio :",
                            id: "5MEKf+",
                          })}
                        </p>
                        <p className="font-semibold text-primary md:ml-4">
                          {user?.bio}
                        </p>
                      </div>
                    )}
                    <button
                      onClick={() => setEditInfo(true)}
                      className="flex items-center justify-center px-4 py-2 mt-8 text-white rounded-full md:hidden bg-primary"
                    >
                      <div>
                        {intl.formatMessage({
                          defaultMessage: "Modifica informazioni",
                          id: "qtHWsh",
                        })}
                      </div>
                    </button>
                  </div>
                </div>
                {(user.has_structures || user.has_experiences) && (
                  <>
                    <div className="my-4 border-2 border-t border-secondary"></div>
                    <div className="flex items-center w-full md:justify-between">
                      <div className="flex items-center gap-x-3">
                        <FontAwesomeIcon
                          icon={faListCheck}
                          className="w-[26px] md:w-6 md:text-lg"
                        />
                        <h1 className="text-lg text-xl font-semibold text-primary">
                          {intl.formatMessage({
                            defaultMessage: "Gestione notifiche",
                            id: "S38e/O",
                          })}
                        </h1>
                      </div>
                      <button
                        onClick={() => setEditNotification(true)}
                        className="hidden md:flex items-center justify-between px-4 py-1.5 text-white rounded-md bg-primary "
                      >
                        {intl.formatMessage({
                          defaultMessage: "Modifica selezione",
                          id: "eWv2Qm",
                        })}
                      </button>
                    </div>
                    <div className="flex flex-col w-full md:flex-row">
                      <div className="justify-center hidden w-full py-2 md:flex md:justify-start md:w-1/4"></div>
                      <div className="flex flex-col w-full divide-y md:w-3/4 divide-primary">
                        <div className="flex flex-row items-center justify-between gap-4 py-2 md:py-4">
                          <p className="text-base md:text-lg font-semibold md:min-w-[250px]">
                            {intl.formatMessage({
                              defaultMessage:
                                "Notifiche di prenotazione su whatsapp?",
                              id: "TkF90n",
                            })}
                          </p>
                          <div className="px-4 py-1 font-semibold bg-white border rounded-md md:ml-4 text-primary border-primary">
                            {user?.whatsapp_notifications
                              ? "Attivo"
                              : "Disattivo"}
                          </div>
                        </div>
                        <button
                          onClick={() => setEditNotification(true)}
                          className="flex items-center justify-center px-4 py-2 mt-8 text-white rounded-full md:hidden bg-primary"
                        >
                          <div>
                            {intl.formatMessage({
                              defaultMessage: "Modifica selezione",
                              id: "eWv2Qm",
                            })}
                          </div>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center w-full h-full min-h-[368px]">
            <UiduSpinner size="medium" className="text-primary" />
          </div>
        )}
      </div>
    </BaseComponent>
  );
}
