import {
  faCircleQuestion,
  faQuestionCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import UiduSpinner from "@uidu/spinner";
import UiduTooltip from "@uidu/tooltip";
import axios from "axios";
import React, { useContext, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { FeeContext } from "../App";

export default function ModalPrice({
  relatedToId,
  relatedToType,
  price,
  setPrice,
  setOpenModal,
  setPrices,
  prices,
}) {
  const [loading, setLoading] = useState(false);
  const [newPrice, setNewPrice] = useState(price ? price.price : 0);
  const [error, setError] = useState("");
  const { fees } = useContext(FeeContext);

  const handleSubmit = () => {
    setLoading(true);
    setError("");
    const fromDate = document.getElementById("fromDate").value;
    const toDate = document.getElementById("toDate").value;
    const token = localStorage.getItem("token");

    if (!token) {
      setError("Non sei autorizzato ad eseguire questa azione");
      setLoading(false);
      return;
    }

    if (!fromDate || !toDate || !newPrice) {
      setError("Compila tutti i campi obbligatori");
      setLoading(false);
      return;
    }

    if (new Date(fromDate) > new Date(toDate)) {
      setError("La data di inizio deve essere precedente a quella di fine");
      setLoading(false);
      return;
    }

    const data = {
      related_to: relatedToType,
      related_id: relatedToId,
      price: newPrice,
      from_date: fromDate,
      to_date: toDate,
    };

    if (price) {
      axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/priceUpdate/${price._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setPrices(
            prices.map((p) => {
              if (p._id === price._id) {
                return res.data;
              }
              return p;
            })
          );
          setOpenModal(false);
          setPrice(null);
        })
        .catch((err) => {
          setError("Errore durante la modifica del prezzo");
          setLoading(false);
        });
    } else {
      const isOverlapping = (newFromDate, newToDate, existingPrices) => {
        return existingPrices.some((price) => {
          const existingFromDate = new Date(price.from_date);
          const existingToDate = new Date(price.to_date);
          return (
            (newFromDate >= existingFromDate &&
              newFromDate <= existingToDate) ||
            (newToDate >= existingFromDate && newToDate <= existingToDate) ||
            (newFromDate <= existingFromDate && newToDate >= existingToDate)
          );
        });
      };

      if (isOverlapping(new Date(fromDate), new Date(toDate), prices)) {
        setError("Esiste già un prezzo in questo intervallo di date");
        setLoading(false);
        return;
      }
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/createPrice`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setPrices([...prices, res.data]);
          setOpenModal(false);
        })
        .catch((err) => {
          setError("Errore durante la creazione del prezzo");
          setLoading(false);
        });
    }
  };

  return (
    <ModalTransition>
      <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
        <Modal
          onClose={() => {
            setOpenModal(false);
          }}
          className="mt-0 md:mt-10"
          shouldCloseOnOverlayClick={false}
          width="large"
        >
          <ModalHeader className="border-b">
            <div className="flex items-center gap-x-4">
              <h3 className="text-2xl">
                {price ? "Modifica prezzo" : "Aggiungi prezzo"}
              </h3>
            </div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setPrice(null);
                setOpenModal(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </ModalHeader>
          <ModalBody>
            <div className="flex flex-col my-4 mb-6 gap-y-4">
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:items-center">
                <p className="font-semibold">Valido dal *</p>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="date"
                  id="fromDate"
                  defaultValue={
                    price?.from_date
                      ? new Date(price.from_date).toISOString().slice(0, 10)
                      : ""
                  }
                />
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:items-center">
                <p className="font-semibold">Valido fino al *</p>
                <input
                  className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                  type="date"
                  id="toDate"
                  defaultValue={
                    price?.to_date
                      ? new Date(price.to_date).toISOString().slice(0, 10)
                      : ""
                  }
                />
              </div>
              <div className="flex p-6 mt-2 mb-4 text-white rounded-md bg-primary">
                <div className="flex flex-col justify-between w-full text-sm">
                  <div className="flex flex-col gap-y-4">
                    <div className="flex items-center text-lg gap-x-4">
                      <FontAwesomeIcon icon={faCircleQuestion} />
                      <p className="font-semibold">
                        Come viene calcolato il prezzo della camera? &nbsp; 🤔
                      </p>
                    </div>
                    <p>
                      Esco applica una commissione del {fees.structures.fee}%
                      sul prezzo della stanza che viene visualizzata sul
                      portale. Il prezzo che imposti sarà quello che riceverai
                      al netto delle commissioni di stripe equivalente al 1,5% +
                      0,25€ per transazione o del 2,5% per i pagamenti con carta
                      britannica. In modo da ottimmizzare le prenotazioni, ti
                      consigliamo di mantenere un prezzo in linea al proprio
                      sito personale o ad altri portali di prenotazione.
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid items-center gap-4 md:grid-cols-2">
                <div className="grid grid-cols-2 gap-x-4">
                  <div>
                    <p className="font-semibold">Prezzo a notte *</p>
                    <p className="text-xs text-primary">Commissioni escluse</p>
                  </div>
                  <CurrencyInput
                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border-2 border-gray-200 rounded appearance-none focus:outline-none focus:shadow-outline"
                    placeholder="€"
                    id="price"
                    min={0}
                    prefix="€ "
                    decimalsLimit={2}
                    decimalScale={2}
                    allowNegativeValue={false}
                    defaultValue={price?.price ? price.price / 100 : 0}
                    onValueChange={(value, name) => {
                      setNewPrice(parseFloat(value.replace(",", ".")) * 100);
                    }}
                  />
                </div>
                <div className="flex items-center justify-between gap-x-4">
                  <div>
                    <p className="font-semibold">Prezzo a notte</p>
                    <div className="flex text-xs text-primary gap-x-2">
                      <p>Visibile sul portale</p>
                      <UiduTooltip
                        content="il prezzo comprensivo delle commissioni di Esco arrotondato per eccesso, sarà quello che il cliente vedrà sul portale"
                        position="bottom"
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          className="cursor-pointer text-primary"
                        />
                      </UiduTooltip>
                    </div>
                  </div>
                  {newPrice > 0 ? (
                    <p className="text-2xl font-bold">
                      €{" "}
                      {Math.ceil(
                        newPrice / 100 +
                          (fees.structures.fee * newPrice) / 10000
                      ).toFixed(0) + ",00"}
                    </p>
                  ) : (
                    <p className="text-2xl font-bold">€ 0</p>
                  )}
                </div>
              </div>
            </div>
            {error && (
              <div className="px-4 py-2 mb-4 text-sm text-center text-white bg-red-500 rounded-md">
                {error}
              </div>
            )}
          </ModalBody>
          <ModalFooter className="border-t">
            <div className="flex flex-col items-center justify-end w-full gap-4 md:flex-row">
              <button
                className="w-full h-10 text-sm bg-gray-200 rounded-lg md:w-28 "
                onClick={() => {
                  setOpenModal(false);
                  setPrice(null);
                }}
              >
                Annulla
              </button>
              <button
                className={`w-full h-10 text-sm text-white rounded-lg bg-primary md:w-52 ${
                  loading && "cursor-not-allowed"
                }`}
                onClick={() => {
                  if (!loading) {
                    handleSubmit();
                  }
                }}
              >
                {loading ? (
                  <UiduSpinner size="small" invertColor />
                ) : price ? (
                  "Salva modifiche"
                ) : (
                  "Aggiungi prezzo"
                )}
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </ModalTransition>
  );
}
