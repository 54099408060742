import { useLoadScript } from "@react-google-maps/api";
import UiduSpinner from "@uidu/spinner";
import AWS from "aws-sdk";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ImageUploading from "react-images-uploading";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-input-2";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import plus from "../assets/icons/PLUS.svg";
import LoadingSpinnerPage from "./LoadingSpinnerPage";

const ImageUploader = React.memo(
  ({ images, uploadedImages, onChange, isUploading, intl }) => {
    console.log("uploadedImages", uploadedImages);
    return (
      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        maxNumber={1}
        acceptType={["jpg", "jpeg", "png"]}
        maxFileSize={10 * 1024 * 1024}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <div className="relative w-full h-full upload__image-wrapper">
            <button
              className="border-dashed border-gray-400 rounded-[40px] text-gray-700 w-full text-center h-full flex flex-col justify-center items-center bg-white"
              style={{
                backgroundColor: isDragging ? "lightblue" : "white",
                padding: uploadedImages.length > 0 ? "0" : "4rem",
                borderWidth: uploadedImages.length > 0 ? "0" : "4px",
              }}
              onClick={onImageUpload}
              {...dragProps}
            >
              {isUploading ? (
                <UiduSpinner size="small" className="text-gray-500" />
              ) : uploadedImages.length > 0 && !isUploading ? (
                <img
                  src={uploadedImages[0]}
                  alt="profile"
                  className="rounded-[30px] w-48 h-48 object-cover"
                />
              ) : (
                <>
                  <img src={plus} alt="plus" className="w-16 h-16 p-2 mb-1" />
                  {!errors && (
                    <>
                      {intl.formatMessage({
                        defaultMessage: "foto profilo",
                        id: "2y0jCl",
                      })}
                    </>
                  )}
                  {errors && (
                    <div className="w-32">
                      {errors.maxNumber && (
                        <span className="text-red-500">
                          {intl.formatMessage({
                            defaultMessage:
                              "Hai raggiunto il numero massimo di immagini",
                            id: "6eOasy",
                          })}
                        </span>
                      )}
                      {errors.acceptType && (
                        <span className="text-red-500">
                          {intl.formatMessage({
                            defaultMessage:
                              "Il formato dell'immagine non è valido",
                            id: "Ea4RtF",
                          })}
                        </span>
                      )}
                      {errors.maxFileSize && (
                        <span className="text-red-500">
                          {intl.formatMessage({
                            defaultMessage:
                              "Immagine troppo grande, la dimensione massima è 10MB",
                            id: "qsqn8O",
                          })}
                        </span>
                      )}
                    </div>
                  )}
                </>
              )}
            </button>
          </div>
        )}
      </ImageUploading>
    );
  }
);

export default function EditUser({ user, setOpenEditInfo, setUser }) {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const intl = useIntl();
  const [uploadedImages, setUploadedImages] = useState(
    user?.profile_pic ? [user.profile_pic] : []
  );
  const [address, setAddress] = useState(
    user?.address?.formatted_address ?? ""
  );
  const [phone, setPhone] = useState(
    user?.phone_number === "-1" || user?.phone_number === null
      ? ""
      : user?.phone_number
  );
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
  });
  const [errors, setErrors] = useState([
    { id: "name", error: null },
    { id: "surname", error: null },
    { id: "profile_name", error: null },
    { id: "bio", error: null },
  ]);

  const uploadFile = async () => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });
    const tempImages = [];
    setIsUploading(true);

    images.forEach((image, index) => {
      if (
        !uploadedImages.includes(
          `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
        )
      ) {
        const params = {
          Bucket: S3_BUCKET,
          Key: image.file.name,
          Body: image.file,
        };

        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();

        upload.then((err, data) => {
          tempImages.push(
            `https://escopublic.s3.eu-north-1.amazonaws.com/${image.file.name}`
          );
          if (index === images.length - 1) {
            setImages([]);
            setIsUploading(false);
            setUploadedImages((prev) => {
              return [tempImages];
            });
          }
        });
      } else {
        if (index === images.length - 1) {
          setImages([]);
          setIsUploading(false);
          setUploadedImages((prev) => {
            return [tempImages];
          });
        }
      }
    });
  };

  const updateUser = async () => {
    const token = localStorage.getItem("token");
    const name = document.getElementById("name").value;
    const surname = document.getElementById("surname").value;
    const profile_name =
      user?.has_structures || user?.has_experiences
        ? document.getElementById("profile_name").value
        : null;
    const bio =
      user?.has_structures || user?.has_experiences
        ? document.getElementById("biography").value
        : null;
    let results = [];
    let latLng = {};
    let addressData = {};

    let currentErrors = [
      { id: "name", error: null },
      { id: "surname", error: null },
      { id: "profile_name", error: null },
      { id: "bio", error: null },
    ];

    if (!name || (name.length > 0 && name.length < 2)) {
      console.log("name error");
      currentErrors.find((e) => e.id === "name").error = intl.formatMessage({
        defaultMessage:
          "Specifica il nome, dev'essere lungo almeno di 2 caratteri!",
        id: "y8byOR",
      });
    }

    if (!surname || (surname.length > 0 && surname.length < 2)) {
      currentErrors.find((e) => e.id === "surname").error = intl.formatMessage({
        defaultMessage:
          "Specifica il cognome, dev'essere lungo almeno di 2 caratteri",
        id: "eoYKX8",
      });
    }

    if (
      (!profile_name || (profile_name.length > 0 && profile_name.length < 2)) &&
      (user?.has_structures || user?.has_experiences)
    ) {
      currentErrors.find((e) => e.id === "profile_name").error =
        intl.formatMessage({
          defaultMessage: "Specifica il nome visibile sul portale",
          id: "901MJY",
        });
    }

    if (
      (!bio || bio.length > 250 || bio === "") &&
      (user?.has_structures || user?.has_experiences)
    ) {
      currentErrors.find((e) => e.id === "bio").error = intl.formatMessage({
        defaultMessage:
          "La biografia deve essere definita e lunga al massimo 250 caratteri",
        id: "uhcB14",
      });
    }

    if (currentErrors.find((e) => e.error !== null)) {
      setErrors(currentErrors);
      return;
    }

    if (address && address.length > 0) {
      results = await geocodeByAddress(address);
      latLng = await getLatLng(results[0]);
      addressData = {
        address: address,
        village:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "locality"
          )?.long_name ?? "",
        municipality:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "administrative_area_level_3"
          )?.long_name ?? "",
        province:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "administrative_area_level_2"
          )?.short_name ?? "",
        country:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "country"
          )?.long_name ?? "",
        postal_code:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "postal_code"
          )?.long_name ?? "",
        street_number:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "street_number"
          )?.long_name ?? "",
        route:
          results[0]?.address_components?.find(
            (component) => component.types[0] === "route"
          )?.long_name ?? "",
        latitude: latLng?.lat ?? null,
        longitude: latLng?.lng ?? null,
        place_id: results[0]?.place_id ?? null,
        formatted_address: results[0]?.formatted_address ?? null,
      };

      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/address/create`,
          addressData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          const data = {
            name,
            surname,
            ...(user?.has_structures || user?.has_experiences
              ? {
                  profile_name: profile_name,
                  bio: bio,
                }
              : {}),
            phone_number: !phone || phone.length < 5 ? -1 : phone,
            address: res.data._id,
            ...(uploadedImages.length > 0 && {
              profile_pic:
                uploadedImages[0][0].length > 1
                  ? uploadedImages[0][0]
                  : uploadedImages[0],
            }),
          };

          await axios
            .put(
              `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user._id}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("ressss", res);
              setUser(res.data);
              setOpenEditInfo(false);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const data = {
        name,
        surname,
        ...(user?.has_structures || user?.has_experiences
          ? {
              profile_name: profile_name,
              bio: bio,
            }
          : {}),
        phone_number: !phone || phone.length < 5 ? -1 : phone,
        ...(uploadedImages.length > 0 && {
          profile_pic:
            uploadedImages[0][0].length > 1
              ? uploadedImages[0][0]
              : uploadedImages[0],
        }),
      };

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/api/user/edit/${user._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setUser(res.data);
          setOpenEditInfo(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onChange = useCallback((imageList) => {
    setImages(imageList);
  }, []);

  useEffect(() => {
    if (images.length > 0) {
      uploadFile();
    }
  }, [images]);

  useEffect(() => {
    if (JSON.stringify(uploadedImages) !== JSON.stringify(user.profile_pic)) {
      //   updateUser();
    }
  }, [uploadedImages]);

  if (!isLoaded) {
    return <LoadingSpinnerPage />;
  }

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex flex-col w-full md:flex-row gap-x-8 ">
        <div className="self-center w-48 h-48 py-2">
          <ImageUploader
            images={images}
            uploadedImages={uploadedImages}
            onChange={onChange}
            isUploading={isUploading}
            intl={intl}
          />
        </div>
        <div className="flex flex-col w-full py-4 md:w-3/4 gap-y-2">
          <p className="font-semibold">
            {intl.formatMessage({
              defaultMessage: "Nome",
              id: "CH/6DN",
            })}
          </p>
          <div className="relative flex flex-col w-full gap-y-4">
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="name"
              defaultValue={user?.name}
              placeholder={intl.formatMessage({
                defaultMessage: "Nome",
                id: "CH/6DN",
              })}
              //   onChange={() => updateExperience()}
            ></input>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "name")?.error}
              </p>
            }
          </div>
          <div className="relative flex flex-col w-full gap-y-2">
            <p className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Cognome",
                id: "r86/aQ",
              })}
            </p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="surname"
              defaultValue={user?.surname}
              placeholder={intl.formatMessage({
                defaultMessage: "Cognome",
                id: "r86/aQ",
              })}
              //   onChange={() => updateExperience()}
            ></input>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "surname")?.error}
              </p>
            }
          </div>
        </div>
      </div>
      {(user?.has_structures || user?.has_experiences) && (
        <>
          <div className="relative flex flex-col w-full gap-y-2">
            <p className="font-semibold">
              {intl.formatMessage({
                defaultMessage: "Nome visibile sul portale",
                id: "I2dYyU",
              })}
            </p>
            <input
              className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="profile_name"
              defaultValue={
                user?.profile_name ? user?.profile_name : `${user?.name}`
              }
              placeholder={intl.formatMessage({
                defaultMessage: "Nome visibile sul portale",
                id: "I2dYyU",
              })}
              //   onChange={() => updateExperience()}
            ></input>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "profile_name")?.error}
              </p>
            }
          </div>
          <div className="relative flex flex-col w-full gap-y-2">
            <p className="font-semibold">Bio</p>
            <textarea
              className="h-full p-4 border-2 border-gray-200 rounded-md resize-none md:p-6 "
              id="biography"
              defaultValue={user?.bio}
            ></textarea>
            {
              <p className="-mt-2 text-sm text-red-400">
                {errors.find((e) => e.id === "bio")?.error}
              </p>
            }
          </div>
        </>
      )}
      <div className="relative flex flex-col w-full gap-y-2">
        <p className="font-semibold">
          {intl.formatMessage({
            defaultMessage: "Numero di telefono",
            id: "4UqxID",
          })}
        </p>
        <PhoneInput
          country="it"
          regions={"europe"}
          placeholder={intl.formatMessage({
            defaultMessage: "Numero di telefono",
            id: "4UqxID",
          })}
          value={phone}
          onChange={(phone) => setPhone(phone)}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          searchStyle={{
            width: "100%",
            height: "35px",
            border: "1px solid rgb(229 231 235)",
          }}
          inputStyle={{
            width: "100%",
            height: "35px",
            border: "1px solid rgb(229 231 235)",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
          }}
          specialLabel=""
        />
      </div>
      <div className="relative flex flex-col w-full gap-y-2">
        <p className="font-semibold">
          {intl.formatMessage({
            defaultMessage: "Indirizzo di residenza",
            id: "Nz4rV0",
          })}
        </p>
        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          // onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: intl.formatMessage({
                    defaultMessage: "Inserisci il tuo indirizzo",
                    id: "s8FyEg",
                  }),
                  className:
                    "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline",
                })}
              />
              <div className="relative z-30 w-full bg-white rounded-b-lg shadow-lg">
                <div className="absolute top-0 left-0 w-full overflow-y-auto bg-gray-200 rounded-b-lg max-h-60">
                  {loading ? (
                    <div className="w-full px-3 py-1 bg-white">
                      <UiduSpinner size="small" />
                    </div>
                  ) : (
                    <>
                      {suggestions.map((suggestion) => {
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion)}
                            key={suggestion.id}
                            className="w-full px-3 py-1 bg-white cursor-pointer hover:bg-sky-50"
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
      <div className="flex justify-end w-full mb-4 gap-x-8">
        <button
          className="flex items-center justify-center px-4 py-2 text-white bg-gray-400 rounded-md"
          type="button"
          onClick={() => setOpenEditInfo(false)}
        >
          {intl.formatMessage({
            defaultMessage: "Annulla",
            id: "tfqu9h",
          })}
        </button>
        <button
          className="flex items-center justify-center px-4 py-2 text-white rounded-md bg-primary"
          type="button"
          onClick={() => updateUser()}
        >
          {intl.formatMessage({
            defaultMessage: "Salva",
            id: "eRxNJP",
          })}
        </button>
      </div>
    </div>
  );
}
