import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import { FavouriteContext } from "../App";
import heart from "../assets/heart.svg";
import heartFull from "../assets/heartFull.svg";
import cameraPrimary from "../assets/icons/CAMERA_PRIMARY.svg";
import escobox from "../assets/icons/ESCOBOXWHITE.svg";
import geotag from "../assets/icons/GEOTAG.svg";
import personePrimary from "../assets/icons/PERSONE_PRIMARY.svg";
import stella from "../assets/icons/STELLA.svg";
import ExperienceIcon from "./ExperienceIcon";

export default function SingleBox({ item, selectedTab }) {
  const navigate = useNavigate();
  const intl = useIntl();
  const notify = (msg) =>
    toast.success(msg, {
      toastId: Math.random().toString(),
      position: "bottom-left",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      icon: (
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="text-2xl text-primary"
        />
      ),
      containerId: `SingleBoxContainer`,
    });

  const { favourite, setFavourite } = useContext(FavouriteContext);

  const avarageReviews = (reviews) => {
    let sum = 0;
    for (let i = 0; i < reviews.length; i++) {
      sum += reviews[i].rating;
    }
    return sum / reviews.length;
  };

  const addToFavourites = async () => {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (!user) {
      navigate("/login");
      return;
    } else {
      if (
        favourite &&
        favourite.length > 0 &&
        favourite[0].escoBoxes.find(
          (escoBox) => escoBox.escoBox_id === item._id
        )
      ) {
        const newEscoBoxes = favourite[0].escoBoxes.filter(
          (escoBox) => escoBox.escoBox_id !== item._id
        );
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: newEscoBoxes,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Box rimosso dai preferiti con successo.",
                id: "sXG1z6",
              })
            );
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/favouriteUpdate`,
            {
              user_id: user,
              escoBoxes: [
                ...(favourite && favourite.length > 0
                  ? favourite[0].escoBoxes
                  : []),
                { escoBox_id: item._id },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setFavourite(res.data);
            notify(
              intl.formatMessage({
                defaultMessage: "Box aggiunto ai preferiti con successo.",
                id: "fLehyd",
              })
            );
          });
      }
    }
  };

  return (
    <div
      key={item._id}
      className="relative flex flex-col border rounded-[30px] shadow-lg cursor-pointer mb-14 hover:shadow-xl"
      onClick={() => {
        navigate(`/escoboxes/${item._id}`);
      }}
    >
      <div
        style={{
          backgroundImage: `url(${encodeURI(item.cover)})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        alt={item.name}
        className="relative w-full h-56 rounded-[30px] shadow-[inset_0px_-60px_40px_0px_rgba(0,0,0,0.6)]"
      />
      <div className="absolute flex flex-row items-start justify-between w-full p-2 top-2">
        <div className="flex items-center mt-1">
          <div className="relative z-10 flex items-center justify-center rounded-full w-7 h-7 bg-primary">
            <img src={escobox} alt="escobox" className="w-4 h-4" />
          </div>
          <div className="flex items-center justify-center pl-4 pr-3 -ml-3 bg-white rounded-r-full h-7 text-primary">
            <span className="font-bold">esco</span>&nbsp;
            <span className="font-light">box</span>
          </div>
        </div>
        <div className="flex flex-col items-center gap-y-2">
          <button
            className="flex items-center justify-center bg-white rounded-full line-clamp-2 w-9 h-9"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              addToFavourites();
            }}
          >
            {/* <img src={heart} alt="heart" className="w-5" /> */}
            {favourite &&
            favourite.length > 0 &&
            favourite[0].escoBoxes.find(
              (escoBox) => escoBox.escoBox_id === item._id
            ) ? (
              <img src={heartFull} alt="heartFull" className="w-5" />
            ) : (
              <img src={heart} alt="heart" className="w-5" />
            )}
          </button>
          {item.guest_number && (
            <div className="relative flex items-center justify-center bg-white rounded-full w-9 h-9">
              <div className="absolute flex items-center pr-0.5 justify-center w-3.5 h-3.5 rounded-full bg-primary -top-0.5 left-0 border border-white">
                <div className="text-[9px] text-white text-center">
                  {item.guest_number}
                </div>
              </div>
              <img src={personePrimary} alt="numero persone" className="w-6" />
            </div>
          )}
          {item.duration && (
            <div className="relative flex items-center justify-center bg-white rounded-full w-9 h-9">
              <div className="absolute flex items-center justify-center pr-0.5 w-3.5 h-3.5 rounded-full bg-primary -top-0.5 left-0 border border-white">
                <div className="text-[9px] text-white">{item.duration}</div>
              </div>
              <img src={cameraPrimary} alt="numero notti" className="w-5" />
            </div>
          )}
        </div>
      </div>
      <div className="relative ">
        <div className="absolute w-full px-2 -mt-16 font-bold text-center text-white">
          {item.name}
        </div>
        <div className="absolute flex items-center justify-center w-full -mt-4">
          <div className="flex items-center justify-center p-1 bg-white rounded-full w-fit gap-x-1">
            {item.category
              .filter((c, index, self) => self.indexOf(c) === index)
              .map((c, index) => (
                <div
                  className="flex items-center justify-center rounded-full w-7 h-7 "
                  key={`${c}-${index}`}
                  style={
                    c === "wine"
                      ? { backgroundColor: "#612430" }
                      : c === "art"
                      ? { backgroundColor: "#C8531C" }
                      : c === "outdoor"
                      ? { backgroundColor: "#4C8AFB" }
                      : c === "authentic"
                      ? { backgroundColor: "#CA9526" }
                      : null
                  }
                >
                  <ExperienceIcon kind={c} dimension={4} />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 w-full h-full p-2 mt-4">
        <div className="flex flex-row justify-around text-sm">
          <div className="flex items-start w-3/4 gap-x-2">
            <img src={geotag} alt="geotag" className="w-4" />
            <div>{item.city}</div>
          </div>
          <div className="flex items-start justify-end w-1/4 h-full gap-x-2">
            <img src={stella} alt="stella" className="w-4" />
            <div>{item.reviews ? avarageReviews(item.reviews) : 0}/5</div>
          </div>
        </div>
      </div>
      <div className="p-3 pt-0 pb-8 overflow-hidden text-sm rounded-b-lg">
        <div className="line-clamp-3">{item.description}</div>
      </div>
      <div className="relative flex items-center justify-center w-full">
        <div className="absolute flex items-center justify-center w-4/5 px-4 py-2 bg-gray-100 rounded-full gap-x-2">
          <div className="font-thin">
            {intl.formatMessage({
              defaultMessage: "a partire da",
              id: "JOSPXT",
            })}
          </div>
          <div className="font-bold">
            {Math.ceil(item.price / 100).toFixed(0)}€
          </div>
        </div>
      </div>
    </div>
  );
}
