import {
  faCalendarCheck,
  faCalendarDays,
  faEllipsisH,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import calendario from "../../assets/icons/CALENDARIO.svg";
import check from "../../assets/icons/CHECK.svg";
import messaggi from "../../assets/icons/MESSAGGI.svg";
import xmark from "../../assets/icons/XMARK.svg";

import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTransition,
} from "@uidu/modal-dialog";
import persone from "../../assets/icons/PERSONE.svg";
import BaseComponent from "../BaseComponent";
import PopUp from "../PopUp";

function BookingCard({ room, value, structure, bookingsForTheDay }) {
  const [roomBookings, setRoomBookings] = useState(
    bookingsForTheDay.filter(
      (booking) => booking.room_id === room._id && booking.user_id
    ) || []
  );
  const [action, setAction] = useState(null);
  const [bookingToEdit, setBookingToEdit] = useState(null);

  const updateBooking = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if (action === "confirm" || action === "delete") {
        await axios
          .put(
            `${process.env.REACT_APP_SERVER_URL}/api/bookings/edit/${bookingToEdit._id}`,
            {
              status: action === "confirm" ? "confirmed" : "rejected",
            }
          )
          .then((res) => {
            console.log(res.data);
            if (res.data.status === "confirmed") {
              setRoomBookings(
                roomBookings.map((booking) => {
                  if (booking._id === bookingToEdit._id) {
                    return {
                      ...booking,
                      status: "confirmed",
                    };
                  }
                  return booking;
                })
              );
            } else {
              setRoomBookings(
                roomBookings.filter(
                  (booking) => booking._id !== bookingToEdit._id
                )
              );
            }
            setAction(null);
            setBookingToEdit(null);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (action === "propose") {
        setAction(null);
        setBookingToEdit(null);
      }
    }
  };

  useEffect(() => {
    if (bookingToEdit) {
      updateBooking();
    }
  }, [bookingToEdit]);

  return (
    <div className="flex flex-col w-full gap-y-2">
      <div className="flex justify-between w-full text-primary">
        <h3 className="mb-4 text-xl font-semibold">{room.name}</h3>
        <p>
          {value.toLocaleDateString("it", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </p>
      </div>
      {roomBookings.length > 0 ? (
        <>
          {roomBookings.map((booking) => (
            <div className="grid items-center w-full grid-cols-3 py-4 pl-6 pr-4 border rounded-[30px] border-primary">
              <div className="flex flex-col font-bold border-r gap-y-4 border-primary">
                <div className="flex items-center gap-x-2">
                  <img
                    src={
                      booking.user_id.profile_pic
                        ? booking.user_id.profile_pic
                        : "https://www.gravatar.com/avatar/?d=mp&s=200"
                    }
                    alt="user"
                    className="object-cover w-8 h-8 rounded-full"
                  />
                  <div>
                    {booking.user_id.name} {booking.user_id.surname}
                  </div>
                </div>
                <div className="flex items-center font-normal gap-x-4">
                  <div className="flex items-center gap-x-2">
                    <img src={persone} alt="persone" className="w-5 h-5" />
                    <div>{booking.guests_number.adults} adulti </div>
                  </div>
                  {booking.guests_number.children > 0 && (
                    <>
                      <div>-</div>
                      <div>{booking.guests_number.children} bambini</div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-between w-full col-span-2 gap-x-4">
                <div className="flex flex-col px-4 gap-y-4">
                  <div className="grid items-center w-full grid-cols-2 gap-x-6">
                    <div className="flex items-center gap-x-2">
                      <div className="font-bold">Arrivo:</div>
                      <div>
                        {dayjs(booking.start_date).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <div className="font-bold">Partenza:</div>
                      <div>{dayjs(booking.end_date).format("DD/MM/YYYY")}</div>
                    </div>
                  </div>
                  <div className="grid items-center w-full grid-cols-2 gap-x-6">
                    <div className="flex items-center gap-x-2">
                      <div className="font-bold">Pasti:</div>
                      <div>{booking.meals.map((meal) => meal).join(", ")}</div>
                    </div>
                    <div className="flex items-center gap-x-2">
                      <div className="text-lg font-bold text-gray-600">
                        Status:
                      </div>
                      <div className="flex items-center gap-x-2">
                        {booking.status === "pending" ? (
                          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                        ) : (
                          <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                        )}
                        <div
                          className={`text-xl font-bold ${
                            booking.status === "pending"
                              ? "text-yellow-500"
                              : "text-green-500"
                          }`}
                        >
                          {booking.status === "pending"
                            ? "In attesa"
                            : "Confermato"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PopUp
                  icon={
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      className="text-xs text-white"
                    />
                  }
                  orientation="horizontal"
                  length={3}
                  items={
                    <>
                      {booking.status !== "pending" ? (
                        <Link
                          className="flex items-center w-full h-8 px-6 py-6 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                          to={`#`}
                        >
                          <img
                            src={calendario}
                            alt="modifica"
                            className="w-6 h-6 pr-2"
                          />
                          <div>Dettagli prenotazione</div>
                        </Link>
                      ) : (
                        <>
                          <button
                            className="flex gap-x-4 items-center w-full h-8 px-6 py-6 text-sm rounded-t-[30px] cursor-pointer text-primary hover:bg-gray-50"
                            onClick={() => setAction("confirm")}
                          >
                            <img
                              src={check}
                              alt="modifica"
                              className="w-6 h-6 pr-2"
                            />
                            <div className="text-start">
                              Conferma Prenotazione
                            </div>
                          </button>
                          <div className="mx-4 border-b" />
                          <button
                            className="flex items-center w-full h-8 px-6 py-6 text-sm cursor-pointer gap-x-4 text-primary hover:bg-gray-50"
                            onClick={() => setAction("propose")}
                          >
                            <img
                              src={calendario}
                              alt="modifica"
                              className="w-6 h-6 pr-2"
                            />
                            <div className="text-start">
                              Proponi data alternativa
                            </div>
                          </button>
                          <div className="mx-4 border-b" />
                          <button
                            className="flex items-center w-full h-8 px-6 py-6 text-sm cursor-pointer gap-x-4 text-primary hover:bg-gray-50"
                            onClick={() => setAction("delete")}
                          >
                            <img
                              src={xmark}
                              alt="cancel"
                              className="w-6 h-6 pr-2"
                            />
                            <div className="text-start">
                              Rifiuta prenotazione
                            </div>
                          </button>
                        </>
                      )}
                      <div className="mx-4 border-b" />
                      <Link
                        className="flex gap-x-4 items-center w-full h-8 px-6 py-6 text-sm rounded-b-[30px] cursor-pointer text-primary hover:bg-gray-50"
                        to={`#`}
                      >
                        <img
                          src={messaggi}
                          alt="modifica"
                          className="w-6 h-6 pr-2"
                        />
                        <div className="text-start">
                          Scrivi messaggio all'utente
                        </div>
                      </Link>
                    </>
                  }
                />
              </div>
              <ModalTransition>
                {action !== null && (
                  <div className="fixed inset-0 z-50 bg-black bg-opacity-50">
                    <Modal
                      onClose={() => setAction(null)}
                      className="mt-0 md:mt-5"
                      width="medium"
                    >
                      <ModalHeader>
                        <div className="flex gap-x-2">
                          <FontAwesomeIcon
                            icon={
                              action === "delete"
                                ? faTriangleExclamation
                                : action === "confirm"
                                ? faCalendarCheck
                                : faCalendarDays
                            }
                            className={`text-2xl ${
                              action === "delete"
                                ? "text-yellow-500"
                                : "text-primary"
                            }`}
                          />
                          <h3 className="text-xl">
                            {action === "confirm"
                              ? "Conferma Prenotazione"
                              : action === "propose"
                              ? "Proponi data alternativa"
                              : "Rifiuta prenotazione"}
                          </h3>
                        </div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setAction(null);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        {action === "confirm" ? (
                          <p className="text-gray-600">
                            Sei sicuro di voler confermare la prenotazione?
                          </p>
                        ) : action === "propose" ? (
                          <p className="text-gray-600">
                            Proponi una data alternativa per la prenotazione
                          </p>
                        ) : (
                          <p className="text-gray-600">
                            Sei sicuro di voler rifiutare la prenotazione?
                          </p>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <div className="flex items-center gap-x-4">
                          <button
                            className="px-6 py-2 bg-gray-100 rounded-md"
                            onClick={() => {
                              setAction(null);
                              setBookingToEdit(null);
                            }}
                          >
                            Annulla
                          </button>
                          <button
                            className="px-6 py-2 text-white rounded-md bg-primary"
                            onClick={() => setBookingToEdit(booking)}
                          >
                            {action === "confirm" || action === "delete"
                              ? "Conferma"
                              : "Proponi"}
                          </button>
                        </div>
                      </ModalFooter>
                    </Modal>
                  </div>
                )}
              </ModalTransition>
            </div>
          ))}
        </>
      ) : (
        <div className="py-2 text-gray-600">
          Nessuna prenotazione per questa data
        </div>
      )}
    </div>
  );
}

export default function Bookings({ structures }) {
  const [selectedStructure, setSelectedStructure] = useState(structures[0]);
  const animatedComponents = makeAnimated();
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [rooms, setRooms] = useState([]);
  const [bookingsForTheDay, setBookingsForTheDay] = useState([]);

  const getRooms = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/api/roomsOfStructure/${selectedStructure._id}`
        )
        .then((res) => {
          setRooms(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getBookingsForTheDay = async (date) => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/api/roomsBookingsOnDate`, {
          structure_id: selectedStructure._id,
          date: dayjs(date).endOf("day").toDate(),
        })
        .then((res) => {
          console.log("getBookingsForTheDay", res.data);
          setBookingsForTheDay(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (selectedStructure) {
      setLoading(true);
      getRooms();
    }
  }, [selectedStructure]);

  useEffect(() => {
    if (rooms.length > 0) {
      getBookingsForTheDay(value);
    }
  }, [rooms]);

  return (
    <BaseComponent title="">
      <h1 className="text-2xl text-center md:text-left text-primary">
        Le tue prenotazioni
      </h1>
      <div className="flex flex-col px-4 pt-4 mt-4 mb-12 overflow-y-auto rounded-md gap-y-6">
        <p className="text-gray-600">
          Verifica le prenotazioni per le stanze della struttura selezionata
        </p>
        {structures.length > 1 && (
          <div className="flex items-center justify-between">
            <p className="text-lg font-semibold">Struttura</p>
            <div className="w-2/3">
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                // menuPortalTarget={document.body}
                // menuPosition="absolute"
                defaultValue={
                  selectedStructure && {
                    value: selectedStructure?._id,
                    label: selectedStructure?.name,
                  }
                }
                styles={{
                  control: (provided) => ({
                    ...provided,
                    border: "2px solid rgb(229 231 235)",
                    height: "35px",
                    width: "100%",
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#246155",
                    color: "white",
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  multiValueRemove: (provided, state) => ({
                    ...provided,
                    color: "white",
                    ":hover": {
                      backgroundColor: "#328575",
                    },
                  }),
                }}
                options={structures.map((structure) => ({
                  value: structure._id,
                  label: structure.name,
                }))}
                onChange={(value) => {
                  setSelectedStructure(
                    structures.find(
                      (structure) => structure._id === value.value
                    )
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="w-full border rounded-md shadow-md md:py-4 md:px-6">
          <Calendar
            onChange={(date) => {
              onChange(date);
              setLoading(true);
              getBookingsForTheDay(date);
            }}
            value={value}
            minDate={new Date()}
            prev2Label={null}
            next2Label={null}
          />
        </div>
        <div className="flex flex-col w-full mt-6 gap-y-6">
          {loading ? (
            <div className="flex items-center justify-center w-full h-32">
              <UiduSpinner size="medium" className="text-primary" />
            </div>
          ) : (
            <>
              {rooms.length > 0 && (
                <>
                  {rooms.map((room) => (
                    <BookingCard
                      key={`${room._id}-${value}`}
                      room={room}
                      value={value}
                      structure={selectedStructure}
                      bookingsForTheDay={bookingsForTheDay}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </BaseComponent>
  );
}
