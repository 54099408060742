import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import logoScritta from "../assets/LOGOSCRITTA.svg";

export default function Footer() {
  const intl = useIntl();
  const [openedElements, setOpenedElements] = useState([
    false,
    false,
    false,
    false,
  ]);
  return (
    <footer className="relative w-full">
      <div className="absolute w-full top-0 h-24 md:h-48 bg-white rounded-b-[60px] md:rounded-b-[210px] z-30"></div>
      <div className="relative z-20 flex flex-col items-center justify-center pt-56 gap-y-10 bg-primary">
        <Link to="/" className="flex items-center pt-6 pb-4 gap-x-2">
          <img src={logoScritta} alt="logo esco" className="w-44" />
        </Link>
        <div className="w-full px-8 pb-12 max-w-screen-2xl 2xl:mx-auto">
          <div className="grid grid-cols-1 px-2 text-white md:mx-16 md:grid-cols-4 gap-x-12">
            <div className="flex flex-col w-full pb-3 border-b-2 border-white md:border-b-0 md:pb-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">
                  {intl.formatMessage({
                    defaultMessage: "About us",
                    id: "wGhqrf",
                  })}
                </p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      !openedElements[0],
                      false,
                      false,
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/mission">
                  {intl.formatMessage({
                    defaultMessage: "Mission",
                    id: "RXoqkD",
                  })}
                </Link>
                <Link to="/vision">
                  {intl.formatMessage({
                    defaultMessage: "Vision",
                    id: "eKTi52",
                  })}
                </Link>
                {/* <Link to="/work-with-us">Lavora con noi</Link> */}
                <Link to="/becomePartner">
                  {intl.formatMessage({
                    defaultMessage: "Diventa partner",
                    id: "tVcuIm",
                  })}
                </Link>
              </div>
              {openedElements[0] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/mission">
                    {intl.formatMessage({
                      defaultMessage: "Mission",
                      id: "RXoqkD",
                    })}
                  </Link>
                  <Link to="/vision">
                    {intl.formatMessage({
                      defaultMessage: "Vision",
                      id: "eKTi52",
                    })}
                  </Link>
                  {/* <Link to="/work-with-us">Lavora con noi</Link> */}
                  <Link to="/becomePartner">
                    {intl.formatMessage({
                      defaultMessage: "Diventa partner",
                      id: "tVcuIm",
                    })}
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">
                  {intl.formatMessage({
                    defaultMessage: "Esplora",
                    id: "Ma/kjj",
                  })}
                </p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      !openedElements[1],
                      false,
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/experiences">
                  {intl.formatMessage({
                    defaultMessage: "Esperienze",
                    id: "ipp3co",
                  })}
                </Link>
                <Link to="/structures">
                  {intl.formatMessage({
                    defaultMessage: "Alloggi",
                    id: "TAoh5a",
                  })}
                </Link>
                <Link to="/escoboxes">
                  {intl.formatMessage({
                    defaultMessage: "Esco box",
                    id: "1kkz+W",
                  })}
                </Link>
              </div>
              {openedElements[1] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/experiences">
                    {intl.formatMessage({
                      defaultMessage: "Esperienze",
                      id: "ipp3co",
                    })}
                  </Link>
                  <Link to="/structures">
                    {intl.formatMessage({
                      defaultMessage: "Alloggi",
                      id: "TAoh5a",
                    })}
                  </Link>
                  <Link to="/escoboxes">
                    {intl.formatMessage({
                      defaultMessage: "Esco box",
                      id: "1kkz+W",
                    })}
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 md:border-r-2 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">
                  {intl.formatMessage({
                    defaultMessage: "Supporto",
                    id: "aSgi8u",
                  })}
                </p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      false,
                      !openedElements[2],
                      false,
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/faq">
                  {intl.formatMessage({
                    defaultMessage: "Assistenza",
                    id: "Qz3BtF",
                  })}
                </Link>
                <Link to="/cancelation-policy">
                  {intl.formatMessage({
                    defaultMessage: "Cancellazione",
                    id: "6Mrr/R",
                  })}
                </Link>
                <Link to="/contactUs">
                  {intl.formatMessage({
                    defaultMessage: "Segnala un problema",
                    id: "Dn/vF4",
                  })}
                </Link>
              </div>
              {openedElements[2] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/faq">
                    {intl.formatMessage({
                      defaultMessage: "Assistenza",
                      id: "Qz3BtF",
                    })}
                  </Link>
                  <Link to="/cancelation-policy">
                    {intl.formatMessage({
                      defaultMessage: "Cancellazione",
                      id: "6Mrr/R",
                    })}
                  </Link>
                  <Link to="/contactUs">
                    {intl.formatMessage({
                      defaultMessage: "Segnala un problema",
                      id: "Dn/vF4",
                    })}
                  </Link>
                </div>
              )}
            </div>
            <div className="flex flex-col w-full py-3 border-b-2 border-white md:border-b-0 md:py-0 gap-y-4">
              <div className="flex justify-between w-full">
                <p className="text-lg font-bold">
                  {intl.formatMessage({
                    defaultMessage: "Community",
                    id: "4CrCbD",
                  })}
                </p>
                <button
                  onClick={() => {
                    setOpenedElements([
                      false,
                      false,
                      false,
                      !openedElements[3],
                    ]);
                  }}
                  className="md:hidden"
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
              <div className="flex-col hidden gap-y-4 md:flex">
                <Link to="/community">
                  {intl.formatMessage({
                    defaultMessage: "Community",
                    id: "4CrCbD",
                  })}
                </Link>
                {/* <Link to="/esco-share">Esco share</Link>
              <Link to="/quiz">Quiz</Link> */}
              </div>
              {openedElements[3] && (
                <div className="flex flex-col gap-y-4 md:hidden">
                  <Link to="/community">
                    {intl.formatMessage({
                      defaultMessage: "Community",
                      id: "4CrCbD",
                    })}
                  </Link>
                  {/* <Link to="/esco-share">Esco share</Link>
                  <Link to="/quiz">Quiz</Link> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="rounded-t-[60px] md:rounded-t-[120px] bg-white py-4 text-primary text-sm text-center w-full">
          <div className="hidden px-8 md:block">
            esco.zone |{" "}
            {intl.formatMessage({
              defaultMessage: "Sede legale:",
              id: "OqYvUE",
            })}{" "}
            Via Parrocchia 84, Pertica Bassa 25078, Brescia (Italy) | P.IVA:
            04388370985 | Email: info@escozone.it
          </div>
          <div className="px-8 md:hidden">esco.zone | P.IVA: 04388370985</div>
          <div className="mx-8 my-2 border-t border-primary md:hidden"></div>
          <div className="flex justify-center pt-2 gap-x-6 md:gap-x-16">
            <div className="hidden md:block">2024 © all rights reserved</div>
            <Link
              to="https://app.legalblink.it/api/documents/666478df88c4fd001a95c2ae/privacy-policy-per-siti-web-o-e-commerce-it"
              target="_blank"
            >
              {intl.formatMessage({
                defaultMessage: "Privacy Policy",
                id: "vx0nkZ",
              })}
            </Link>
            <Link
              to="https://app.legalblink.it/api/documents/666478df88c4fd001a95c2ae/cookie-policy-it"
              target="_blank"
            >
              {intl.formatMessage({
                defaultMessage: "Cookie Policy",
                id: "cA0kQY",
              })}
            </Link>
            <Link
              to="https://escopublic.s3.eu-north-1.amazonaws.com/T%26C+RIELABORATI.pdf"
              target="_blank"
            >
              {intl.formatMessage({
                defaultMessage: "Termini di servizio",
                id: "nt15yW",
              })}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
