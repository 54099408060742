import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UiduSpinner from "@uidu/spinner";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import plus from "../../../assets/icons/PLUS.svg";

export default function ManageSlideshow() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [hasButton, setHasButton] = useState(false);
  const categories = ["wine", "art", "outdoor", "authentic"];
  const [video, setVideo] = useState(null);
  const intl = useIntl();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(null);

  const handleSelect = (index) => {
    setSelectedTab(index);
  };

  const uploadFile = async (file) => {
    const S3_BUCKET = "escopublic";
    const REGION = "eu-north-1";

    AWS.config.update({
      accessKeyId: `${process.env.REACT_APP_AWS_ACCES_KEY_ID}`,
      secretAccessKey: `${process.env.REACT_APP_AWS_sECRET_ACCESS_KEY}`,
    });
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    setIsUploading(true);

    const params = {
      Bucket: S3_BUCKET,
      Key: file.name,
      Body: file,
    };

    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();

    upload.then((err, data) => {
      setIsUploading(false);
      setUploadedVideo(
        `https://escopublic.s3.eu-north-1.amazonaws.com/${file.name}`
      );
    });
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("video/")) {
      setVideo(URL.createObjectURL(file));
      uploadFile(file);
    } else {
      alert("Il formato del file non è valido. Carica un video.");
    }
  };

  const handleVideoRemove = () => {
    setVideo(null);
    setUploadedVideo(null);
  };

  return (
    <div>
      {/* here we need to do a tab with the different category so wine, art, outdoor and authentic and for every tab we should add the field necessary*/}
      <Tabs selectedIndex={selectedTab} onSelect={handleSelect}>
        <TabList
          style={{
            borderColor: "#246155",
          }}
        >
          {categories.map((category, index) => (
            <Tab
              key={index}
              style={{
                backgroundColor:
                  selectedTab === index ? "#246155" : "transparent",
                color: selectedTab === index ? "white" : "black",
                borderColor: selectedTab === index ? "#246155" : "transparent",
                fontWeight: "bold",
              }}
            >
              {category}
            </Tab>
          ))}
        </TabList>

        {categories.map((category, index) => (
          <TabPanel
            key={index}
            style={{
              padding: "0.8rem 0",
            }}
          >
            <div className="flex flex-col gap-y-3" key={selectedTab}>
              <p className="font-semibold">Video:</p>
              <input
                type="file"
                accept="video/*"
                onChange={handleVideoUpload}
                className="hidden"
                id="video-upload"
              />
              <label
                htmlFor="video-upload"
                className="border-dashed border-gray-400 rounded-[40px] text-gray-700 w-full text-center h-full flex flex-col justify-center items-center bg-white"
                style={{
                  padding: video ? "0" : "4rem",
                  borderWidth: video ? "0" : "4px",
                  cursor: isUploading ? "not-allowed" : "pointer",
                }}
              >
                {isUploading ? (
                  <div className="flex items-center justify-center h-full min-h-[226px] pointer-events-none">
                    <UiduSpinner size="small" className="text-gray-500" />
                  </div>
                ) : video ? (
                  <>
                    <video
                      src={video}
                      controls
                      className="rounded-[30px] w-48 h-48 object-cover"
                    />
                    <button
                      onClick={handleVideoRemove}
                      className="mt-2 text-red-500 bg-white rounded-[20px] px-2 py-1"
                    >
                      <FontAwesomeIcon ixon={faXmark} />
                    </button>
                  </>
                ) : (
                  <>
                    <img src={plus} alt="plus" className="w-16 h-16 p-2 mb-1" />
                    <span>
                      {intl.formatMessage({
                        defaultMessage: "video",
                        id: "4jAirD",
                      })}
                    </span>
                  </>
                )}
              </label>
              <p className="font-semibold">Descrizione:</p>
              <textarea
                className=" p-4 md:p-6 border-2 border-gray-200 resize-none rounded-[20px] "
                placeholder="Cta"
                rows={2}
                id="description"
                defaultValue={""}
                onChange={() => console.log("description")}
              ></textarea>
              <label
                htmlFor="hasButton"
                className="flex items-center mt-4 text-base font-semibold gap-x-4"
              >
                <input
                  type="checkbox"
                  id="hasButton"
                  name="hasButton"
                  className="hidden"
                  defaultChecked={false}
                />
                <div
                  className={`bg-white border-gray-300 min-w-5 w-5 h-5 border rounded-md shadow-sm text-primary flex items-center justify-center`}
                  onClick={() => {
                    setHasButton(!hasButton);
                  }}
                >
                  {hasButton && (
                    <FontAwesomeIcon className="h-3" icon={faCheck} />
                  )}
                </div>
                <p>aggiungi bottone a slide</p>
              </label>
              {hasButton && (
                <>
                  <p className="font-semibold">Testo bottone:</p>
                  <textarea
                    className=" p-4 md:p-6 border-2 border-gray-200 resize-none rounded-[20px] "
                    placeholder="Testo bottone"
                    rows={2}
                    id="buttonText"
                    defaultValue={""}
                    onChange={() => console.log("description")}
                  ></textarea>
                </>
              )}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
}
